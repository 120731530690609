import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import style from './SubmittedPage.module.scss';
// import styleBtn from '../components/mini/ButtonCustom.module.scss';
// import cx from 'classnames';
import logo from '../assets/logo-korindo-icon.png';
import googleplaybutton from '../assets/google_play_button.png';
import appstorebutton from '../assets/app_store_button.png';
// import Button from '../components/mini/ButtonCustom';
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import PdfDocument from "../components/PdfDocument";
import {RootContext} from '../Home';

export default class SubmittedPage extends React.Component {
	componentDidMount() {
        if (window.location.host === 'openaccount.nhsec.co.id') {
	        const scriptDable = document.createElement("script");
	        scriptDable.text = "(function(d,a,b,l,e,_) {d[b]=d[b]||function(){(d[b].q=d[b].q||[]).push(arguments)};e=a.createElement(l);e.async=1;e.charset='utf-8';e.src='//static.dable.io/dist/dablena.min.js';_=a.getElementsByTagName(l)[0];_.parentNode.insertBefore(e,_);})(window,document,'dablena','script');dablena('init', '004-003-854');dablena('track', 'CompleteRegistration');";
	        document.head.appendChild(scriptDable);

	        const scriptGtag = document.createElement("script");
	        scriptGtag.text = "gtag('event', 'conversion', {'send_to': 'AW-479780240/B0D0CKqjougBEJC74-QB'});";
	        document.head.appendChild(scriptGtag);

			const scriptGtag111022 = document.createElement("script");
	        scriptGtag111022.text = "gtag('event', 'conversion', {'send_to': 'AW-10999426712/4YsJCJzWzOQDEJjd9_wo'});";
	        document.head.appendChild(scriptGtag111022);
		}

	}
	render() {
		const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
		return (
			<RootContext.Consumer>
				{
					store => {
						return (
							<Fragment>
								<div className={ style['submitted-content'] }>
									<div className={ style['check-circle'] } >
										<FontAwesomeIcon icon={faCheck} />
									</div>

									<div className={ style['information'] }>
										<div className={ style['title'] }>
											{store.dispatch({type:'translate', 'text': 'submit_success_title'})}
										</div>

										<div className={ style['description'] }>
										{
											store.state.registration.bank_name.value === 'bca' ? 
											// renderHTML("Dear BCA Account Holder<br>Your application will be processed further.<br>Thank you for submitting an opening for securities.")
											renderHTML(store.dispatch({type:'translate', 'text': 'submit_success_desc_1'}))
											:
											// renderHTML("NHSEC will process your application to be submitted to the Bank first.<br>Thank you for submitting an opening for securities.")
											renderHTML(store.dispatch({type:'translate', 'text': 'submit_success_desc_2'}))
										}
										</div>

										{
											/*
										<PDFDownloadLink
											document={<PdfDocument data={store.state.registration} />}
											fileName="nhsec_download.pdf"
											className={cx(style['pdf-btn'], styleBtn['btn'], styleBtn['blue-btn'], styleBtn['redirect-btn'])}
								        >
								          {({ blob, url, loading, error }) =>
								            loading ? "Loading document..." : "Download Pdf"
								          }
								        </PDFDownloadLink>
								        */
										}
									</div>

									<div className={ style['divider'] }>
										<img src={logo} className={style['img']} alt=""/>
									</div>

									{
										
									<div className={ style['check-progress'] }>
										<div className={ style['info'] }>
											<p>
												<a href="/status" className="link-href">{store.dispatch({type:'translate', 'text': 'click_here'})}</a> {store.dispatch({type:'translate', 'text': 'check_status_label_1'})}
												<br />{store.dispatch({type:'translate', 'text': 'check_status_label_2'})} <a href={window.location.origin} className="link-href">{window.location.origin}</a> {store.dispatch({type:'translate', 'text': 'check_status_label_3'})} <strong className="link-href">{store.dispatch({type:'translate', 'text': 'check_status_label_4'})}</strong>
											</p>
										</div>
									</div>

										
									}

									<div className={style['download-app']}>
										<div className={style['heading']}><p>{store.dispatch({type:'translate', 'text': 'start_investing'})}</p></div>
										<div className={style['app-button-container']}>
											<a href="https://apps.apple.com/id/app/winpro-mobile-2-0/id1223931836?l=id" target="_blank" rel="noopener noreferrer"><img src={appstorebutton} alt="Download NAIK on the App Store"/></a>
											<a href="https://play.google.com/store/apps/details?id=com.nhkorindo.mts&hl=in" target="_blank" rel="noopener noreferrer"><img src={googleplaybutton} alt="Download NAIK on Google Play"/></a>
										</div>

										<div className={style['download-app-hint']}><p><em>{store.dispatch({type:'translate', 'text': 'app_download_hint'})}</em></p></div>
										
										<div className={style['naik-for-pc']}><p><strong><a href="https://cloud.nhsec.co.id/s/8qhY4giRkf0i2p7/download" target="_blank" rel="noopener noreferrer">{store.dispatch({type:'translate', 'text': 'download_naik_for_windows'})}</a></strong></p></div>
									</div>
								</div>
							</Fragment>
						)
					}
				}
			</RootContext.Consumer>
		);
	}
}
