import React from 'react';
import { RootContext } from '../Home';
import style from './InformationPage.module.scss';

const PrivacyPage = (props) => {
  return (
	<RootContext.Consumer>
	{
		store => {
			// console.log(store.state.privacy)
			const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
			return (
				<div className={style['information-content']}>
					<label className={style['title']}>{renderHTML(store.state.privacy.name)}</label>
					<div className={style['description']}>
					{renderHTML(store.state.privacy.content)}
					</div>
				</div>
			)
		}
	}
	</RootContext.Consumer>
  )
}

export default PrivacyPage;