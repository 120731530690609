import React, { Fragment, Component } from 'react';
import style from './AlertCustom.module.scss';
import cx from 'classnames';
import Alert from 'react-bootstrap/Alert';

export default class AlertCustom extends Component {
	render() {
		return (
		    <Fragment>
		    	<Alert 
		    		variant={this.props.type}
		    		bsPrefix={cx(style['alert-custom'],style['alert-' + this.props.type], 'alert' )}
	    		>
					{this.props.message}
				</Alert>
		    </Fragment>
		);
	}
}