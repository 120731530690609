import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import modalotpstyles from "./ModalOTP.module.scss";

class ModalOTP extends Component {

  handleModalOTP = () => {
    this.props.handleModalOTP(false);
  }

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    return (
      <>
        <Modal show={this.props.show} centered={true} contentClassName="testClassName">
          <Modal.Body>
            <h3>Phone Number Validation</h3>
            <form>
              <input type="text" placeholder="Input OTP here" />
              <button>Send</button>
              <button>Get OTP</button>
            </form>
          </Modal.Body>
          <div className={modalotpstyles.modalFooter}>
            <Button variant="primary" onClick={this.handleModalOTP}>
              Tutup
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalOTP;
