import React, { Fragment, Component } from 'react';
import { Link } from "react-router-dom";
import style from './AnchorTextCustom.module.scss';
import cx from 'classnames';

export default class AnchorTextCustom extends Component {
	handleClickAnchorText = (element) => {
		this.props.onClickAnchorText(element);
	}
	render() {
		if (this.props.to === '#') {
			return (
				<Fragment>
					<Link id={this.props.id} to="#" className={cx(style['anchor-text'], style[this.props.className])} onClick={this.handleClickAnchorText}>
						{ this.props.text }
					</Link>
				</Fragment>
			);
		}
		return (
		    <Fragment>
		    	<Link 
		    		id={this.props.id}
		    		to={ this.props.to } 
		    		className={cx(style['anchor-text'], style[this.props.className])}
	    		>
		    		{ this.props.text }
		    	</Link>
		    </Fragment>
		);
	}
}