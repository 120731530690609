import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useParams } from "react-router-dom";
import { RootContext } from '../Home';
import queryString from 'query-string';
// import PDFViewer from 'pdf-viewer-reactjs';
// import ButtonCustom from '../components/mini/ButtonCustom';

// const CustomNavigation = (props) => {
//     return (
//         <div className='button-control'>
//             <ButtonCustom class="yellow-btn" value="Prev" disabled={props.page === 1 ? true : false} onButtonClick={props.handlePrevClick} />
//             <div style={{display:'inline-block'}}>Page {props.page} / {props.pages}</div>
//             <ButtonCustom class="blue-btn" value="Next" disabled={props.page === props.pages ? true : false} onButtonClick={props.handleNextClick} />
//         </div>
//     )
// }

const PdfViewerPage = (props) => {
	let { param } = useParams();
	return (
		<RootContext.Consumer>
		{
			store => {
				if (param === 'bca-provision') {
					return (
						<Worker workerUrl={window.location.origin + '/assets/js/pdf.worker.min.js'}>
						    <Viewer fileUrl="/assets/file/RDNOnline.pdf" />
						</Worker>
					)
				} else if (param === 'bca-statement') {
					return (
						<Worker workerUrl={window.location.origin + '/assets/js/pdf.worker.min.js'}>
						{
							(queryString.parse(window.location.search).n === "1") ? 
							<Viewer fileUrl="/assets/file/PERNYATAAN_NASABAH_RDN_BCA_1.pdf" />
							:
						    <Viewer fileUrl="/assets/file/PERNYATAAN_NASABAH_RDN_BCA_2.pdf" />
						}
						</Worker>
					)
				}  else if(param === 'cimb-kpupr'){
					return (
						<Worker workerUrl={window.location.origin + '/assets/js/pdf.worker.min.js'}>
						{
							(queryString.parse(window.location.search).n === "1") ? 
							<Viewer fileUrl="/assets/file/KPUPR.pdf" />
							:
						    <Viewer fileUrl="/assets/file/KPUPR.pdf" />
						}
						</Worker>
					)
				}
				else {
					return (
						<div></div>
					)
				}
			}
		}
		</RootContext.Consumer>
	)
}

export default PdfViewerPage;
