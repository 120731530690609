import React, { Component, Fragment } from 'react';
import ButtonCustom from '../components/mini/ButtonCustom';
import Form from 'react-bootstrap/Form';
import validate from '../pages/validate';
import {RootContext} from '../Home';
import cx from 'classnames';
import styleRegis from '../pages/RegistrationPage.module.scss';
import { Formik } from 'formik';
import CustomInput from '../components/mini/CustomInput';
import * as Yup from "yup";
import { isMobile } from "react-device-detect";
import Axios from 'axios';

const apiURL = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV;

function array_highlight(newSource, newHighlight) {
    let source = newSource;
    let highlight = newHighlight;
    let newArray = [];

    for (let k = 0; k < highlight.length; k++) {
        newArray.push(source.find(({value}) => value === highlight[k]))
    }

    for (let i = 0; i < source.length; i++) {
        for (let j = 0; j < highlight.length; j++) {
            if (source[i].value !== highlight[j] && !newArray.find(({value}) => value === source[i].value)) {
                newArray.push(source[i]);
                break;
            }
        }
    }
    return newArray;
}

class ContactForCorrespondenceForm extends Component {
    state = {
        masterCity: [],
        masterProvince: [],
        masterDistrict: [],
        masterSubDistrict: []
    }
    onChangeCountry(value) {
        this.setState({
            masterProvince: this.props.master_province.filter((curVal) => {
                                if (typeof value === 'object') {
                                    if (value.value !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                } else {
                                    if (parseInt(value) !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                }
                            })
        })
    }
    onChangeProvince(value) {
        this.setState({
            masterCity: this.props.master_city.filter((curVal) => {
                            if (typeof value === 'object') {
                                return curVal.province_id === value.value;
                            } else {
                                return curVal.province_id === parseInt(value);
                            }
                        })
        });
    }

    // On Change City
    async onChangeCity(selectedCity) {
        let masterDistrictContainer = [];

        let districtResult = await Axios.get(apiURL + '/api/master-data', {
            params: {
                master_data: 'master_district_cimb',
                city_ksei_id: (typeof selectedCity === 'object') ? selectedCity.value : selectedCity
            }
        });

        if(districtResult.data.status) {
            masterDistrictContainer = districtResult.data.data.map((value) => {
                return {value: parseInt(value.id), label: value.name}
            });
            this.setState({
                masterDistrict: masterDistrictContainer
            });
        }
    }

    async onChangeDistrict(selectedDistrict) {
        let masterSubDistrictContainer = [];
        let subDistrictResult = await Axios.get(apiURL + '/api/master-data', {
            params: {
                master_data: 'master_villages_cimb',
                district_cimb: (typeof selectedDistrict === 'object') ? selectedDistrict.value : selectedDistrict
            }
        });

        if(subDistrictResult.data.status) {
            masterSubDistrictContainer = subDistrictResult.data.data.map((value) => {
                return {value: parseInt(value.id), label: value.name}
            });
            this.setState({
                masterSubDistrict: masterSubDistrictContainer
            });
        }        

    }

    async componentDidMount() {
        if (this.props.cur_country) {
            this.setState({
                masterProvince: this.props.master_province.filter((curVal) => {
                                if (typeof this.props.cur_country === 'object') {
                                    if (this.props.cur_country.value !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                } else {
                                    if (parseInt(this.props.cur_country) !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                }
                            })
            })
        }

        if (this.props.cur_province) {
            this.setState({
                masterCity: this.props.master_city.filter((curVal) => {
                                if (typeof this.props.cur_province === 'object') {
                                    return curVal.province_id === this.props.cur_province.value;
                                } else {
                                    return curVal.province_id === parseInt(this.props.cur_province);
                                }
                            })
            });       
        }

        if(this.props.cur_city) {
            let masterDistrictContainer = [];

            let districtResult = await Axios.get(apiURL + '/api/master-data', {
                params: {
                    master_data: 'master_district_cimb',
                    city_ksei_id: (typeof this.props.cur_city === 'object') ? this.props.cur_city.value : this.props.cur_city
                }
            });
    
            if(districtResult.data.status) {
                masterDistrictContainer = districtResult.data.data.map((value) => {
                    return {value: parseInt(value.id), label: value.name}
                });
                this.setState({
                    masterDistrict: masterDistrictContainer
                });
            }
        }

        if(this.props.cur_district_master) {
            let masterSubDistrictContainer = [];
            let subDistrictResult = await Axios.get(apiURL + '/api/master-data', {
                params: {
                    master_data: 'master_villages_cimb',
                    district_cimb: (typeof this.props.cur_district_master === 'object') ? this.props.cur_district_master.value : this.props.cur_district_master
                }
            });
    
            if(subDistrictResult.data.status) {
                masterSubDistrictContainer = subDistrictResult.data.data.map((value) => {
                    return {value: parseInt(value.id), label: value.name}
                });
                this.setState({
                    masterSubDistrict: masterSubDistrictContainer
                });
            }    
        }
        
    }
	render() {
		return (
            <RootContext.Consumer>
                {
                    store => {
                        const countrySelected = [104,119,135,200,46];
                        const masterCountry = array_highlight(store.state.master.master_country, countrySelected);

                        const schema = !isMobile ? Yup.object().shape({
                            country: Yup.string().required('error_valueMissing').nullable(),
                            province: Yup.string().required('error_valueMissing').nullable(),
                            city: Yup.string().required('error_valueMissing').nullable(),
                            district_master: Yup.string().required('error_valueMissing').nullable(),
                            subdistrict_master: Yup.string().required('error_valueMissing').nullable(),
                        })
                        : '';
                        return (
                            <Formik
                                validate={(values) => validate(values, '#contact-for-correspondence')} 
                                validationSchema={schema}
                                onSubmit={values => {
                                    store.dispatch({type:'handleSubmit',field:'registration_contact_for_correspondence', values: values})
                                }}
                                initialValues={{
                                    address_1: store.state.registration.address_1,
                                    address_2: store.state.registration.address_2,
                                    address_3: store.state.registration.address_3,
                                    country: store.state.registration.country,
                                    province: store.state.registration.province,
                                    city: store.state.registration.city,
                                    district: store.state.registration.district,
                                    subdistrict: store.state.registration.subdistrict,
                                    rt: store.state.registration.rt,
                                    rw: store.state.registration.rw,
                                    postal_code: store.state.registration.postal_code,
                                    home_phone: store.state.registration.home_phone,
                                    home_phone_area_code: store.state.registration.home_phone_area_code,
                                    mobile_phone: store.state.registration.mobile_phone,
                                    mobile_phone_country_code: store.state.registration.mobile_phone_country_code,
                                    mobile_phone_country_dialcode: store.state.registration.mobile_phone_country_dialcode,
                                    fax_number: store.state.registration.fax_number,
                                    email_address: store.state.registration.email_address,
                                    district_master: store.state.registration.district_master,
                                    subdistrict_master: store.state.registration.subdistrict_master,
                                    hrel_name: store.state.registration.hrel_name,
                                    hrel_address: store.state.registration.hrel_address,
                                    hrel_telp: store.state.registration.hrel_telp,
                                    hrel_telp_country_code: store.state.registration.hrel_telp_country_code,
                                    hrel_telp_country_dialcode: store.state.registration.hrel_telp_country_dialcode,
                                    hrell_type: store.state.registration.hrell_type
                                }}
                                >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    isSubmitting,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched,
                                }) => (
                                <Fragment>
                                    <Form id="contact-for-correspondence" className="form" validated={errors ? false : true } noValidate onSubmit={handleSubmit}>
                                        {
                                            /*
                                            <Form.Row>
                                                <pre
                                                  className="col-lg-12"
                                                  style={{
                                                    background: '#f6f8fa',
                                                    fontSize: '.65rem',
                                                    padding: '.5rem',
                                                    textAlign: 'left',
                                                  }}
                                                >
                                                  <strong>props</strong> ={' '}
                                                  {JSON.stringify(values, null, 2)}
                                                  <br/>
                                                  <strong>errors</strong> ={' '}
                                                  {JSON.stringify(errors, null, 2)}
                                                  <br/>
                                                  <strong>touched</strong> ={' '}
                                                  {JSON.stringify(touched, null, 2)}
                                                  <br/>
                                                </pre>
                                            </Form.Row>
                                            */
                                        }
                                        <Form.Row>
                                            <CustomInput 
                                                col="12"
                                                labelName={store.dispatch({type:'translate', 'text': 'address'})}
                                                info={store.dispatch({type:'translate', 'text': 'address_info'})}
                                                name="address_1"
                                                type="textarea"
                                                required
                                                maxLength="40"
                                                value={values.address_1}
                                                // handleChange={value => setFieldValue('address_1', value)}
                                                handleChange={(value) => {
                                                    let valUppercase = value ? value.toUpperCase() : "";
                                                    setFieldValue("address_1", valUppercase);
                                                }}
                                                isValid={touched.address_1 && !errors.address_1}
                                                isInvalid={!!errors.address_1}
                                                error={(typeof errors.address_1 === 'object') ? store.dispatch({type:'translate', 'text': errors.address_1.type}).toString().replace('$param$', errors.address_1.param).replace('$param2$', errors.address_1.param2) : store.dispatch({type:'translate', 'text': errors.address_1})}
                                            />
                                        </Form.Row>
                                        <Form.Row>
                                            <CustomInput 
                                                col="4"
                                                labelName={store.dispatch({type:'translate', 'text': 'country'})}
                                                name="country"
                                                type="select"
                                                required
                                                value={values.country}
                                                // onChange={setFieldValue}
                                                onChange={(name,value) => {
                                                    this.onChangeCountry(value);
                                                    setFieldValue(name, value);
                                                    setFieldValue('province', '');
                                                    setFieldValue('city', '');
                                                }}
                                                onBlur={setFieldTouched}
                                                placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                error={(typeof errors.country === 'object') ? store.dispatch({type:'translate', 'text': errors.country.type}).toString().replace('$param$', errors.country.param).replace('$param2$', errors.country.param2) : store.dispatch({type:'translate', 'text': errors.country})}
                                                touched={touched.country}
                                                option={masterCountry}
                                                gap={countrySelected[countrySelected.length - 1]}
                                            />
                                            <CustomInput 
                                                col="4"
                                                labelName={store.dispatch({type:'translate', 'text': 'province'})}
                                                name="province"
                                                type="select"
                                                required
                                                value={values.province}
                                                // onChange={setFieldValue}
                                                onChange={(name,value) => {
                                                    setFieldValue('city', '');
                                                    setFieldValue('district_master', '');
                                                    setFieldValue('subdistrict_master', '');

                                                    setFieldValue('district', '');
                                                    setFieldValue('subdistrict', '');

                                                    this.onChangeProvince(value);
                                                    setFieldValue(name, value);
                                                }}
                                                onBlur={setFieldTouched}
                                                placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                error={(typeof errors.province === 'object') ? store.dispatch({type:'translate', 'text': errors.province.type}).toString().replace('$param$', errors.province.param).replace('$param2$', errors.province.param2) : store.dispatch({type:'translate', 'text': errors.province})}
                                                touched={touched.province}
                                                option={this.state.masterProvince}
                                            />
                                            <CustomInput 
                                                col="4"
                                                labelName={store.dispatch({type:'translate', 'text': 'city'})}
                                                name="city"
                                                type="select"
                                                required
                                                value={values.city}
                                                // onChange={setFieldValue}
                                                onChange={(name, value) => {
                                                    setFieldValue('district_master', '');
                                                    setFieldValue('subdistrict_master', '');

                                                    setFieldValue('district', '');
                                                    setFieldValue('subdistrict', '');

                                                    this.onChangeCity(value);
                                                    setFieldValue(name, value);
                                                }}
                                                onBlur={setFieldTouched}
                                                placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                error={(typeof errors.city === 'object') ? store.dispatch({type:'translate', 'text': errors.city.type}).toString().replace('$param$', errors.city.param).replace('$param2$', errors.city.param2) : store.dispatch({type:'translate', 'text': errors.city})}
                                                touched={touched.city}
                                                option={this.state.masterCity}
                                            />
                                        </Form.Row>

                                        {/* Test Kecamatan dari Master Data */}
                                        <Form.Row>
                                        <CustomInput 
                                                col="4"
                                                labelName={store.dispatch({type:'translate', 'text': 'district'})}
                                                name="district_master"
                                                type="select"
                                                required
                                                value={values.district_master}
                                                onChange={(name, value) => {
                                                    setFieldValue('subdistrict_master', '');
                                                    setFieldValue('subdistrict', '');

                                                    setFieldValue('district', value.label);

                                                    this.onChangeDistrict(value);
                                                    setFieldValue(name, value);
                                                }}
                                                onBlur={setFieldTouched}
                                                placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                error={(typeof errors.district_master === 'object') ? store.dispatch({type:'translate', 'text': errors.district_master.type}).toString().replace('$param$', errors.district_master.param).replace('$param2$', errors.district_master.param2) : store.dispatch({type:'translate', 'text': errors.district_master})}
                                                touched={touched.district_master}                                                
                                                option={this.state.masterDistrict}
                                            />
                                        <CustomInput 
                                                col="4"
                                                labelName={store.dispatch({type:'translate', 'text': 'subdistrict'})}
                                                name="subdistrict_master"
                                                type="select"
                                                required
                                                value={values.subdistrict_master}
                                                onChange={(name, value) => {
                                                    setFieldValue('subdistrict', value.label);
                                                    setFieldValue(name, value);
                                                }}
                                                onBlur={setFieldTouched}
                                                placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                error={(typeof errors.subdistrict_master === 'object') ? store.dispatch({type:'translate', 'text': errors.subdistrict_master.type}).toString().replace('$param$', errors.subdistrict_master.param).replace('$param2$', errors.subdistrict_master.param2) : store.dispatch({type:'translate', 'text': errors.subdistrict_master})}
                                                touched={touched.subdistrict_master}
                                                option={this.state.masterSubDistrict}
                                            />                                            
                                        </Form.Row>
                                        {/* Test Kecamatan dari Master Data */}

                                        {
                                            !store.state.citizenship.foreigner ?
                                            <Form.Row>
                                                {/* <CustomInput 
                                                    col="3"
                                                    labelName={store.dispatch({type:'translate', 'text': 'district'})}
                                                    name="district"
                                                    type="text"
                                                    minLength="1"
                                                    // pattern="^[0-9]*$"
                                                    required
                                                    value={values.district}
                                                    handleChange={handleChange}
                                                    isValid={touched.district && !errors.district}
                                                    isInvalid={!!errors.district}
                                                    error={(typeof errors.district === 'object') ? store.dispatch({type:'translate', 'text': errors.district.type}).toString().replace('$param$', errors.district.param).replace('$param2$', errors.district.param2) : store.dispatch({type:'translate', 'text': errors.district})}
                                                />
                                                <CustomInput 
                                                    col="3"
                                                    labelName={store.dispatch({type:'translate', 'text': 'subdistrict'})}
                                                    name="subdistrict"
                                                    type="text"
                                                    minLength="1"
                                                    // pattern="^[0-9]*$"
                                                    required
                                                    value={values.subdistrict}
                                                    handleChange={handleChange}
                                                    isValid={touched.subdistrict && !errors.subdistrict}
                                                    isInvalid={!!errors.subdistrict}
                                                    error={(typeof errors.subdistrict === 'object') ? store.dispatch({type:'translate', 'text': errors.subdistrict.type}).toString().replace('$param$', errors.subdistrict.param).replace('$param2$', errors.subdistrict.param2) : store.dispatch({type:'translate', 'text': errors.subdistrict})}
                                                /> */}
                                                <CustomInput 
                                                    col="4"
                                                    labelName="RT"
                                                    info={store.dispatch({type:'translate', 'text': 'info_rt_rw'})}
                                                    name="rt"
                                                    type="text"
                                                    minLength="3"
                                                    maxLength="3"
                                                    pattern="^[0-9]*$"
                                                    required
                                                    value={values.rt}
                                                    handleChange={handleChange}
                                                    isValid={touched.rt && !errors.rt}
                                                    isInvalid={!!errors.rt}
                                                    error={(typeof errors.rt === 'object') ? store.dispatch({type:'translate', 'text': errors.rt.type}).toString().replace('$param$', errors.rt.param).replace('$param2$', errors.rt.param2) : store.dispatch({type:'translate', 'text': errors.rt})}
                                                />
                                                <CustomInput 
                                                    col="4"
                                                    labelName="RW"
                                                    info={store.dispatch({type:'translate', 'text': 'info_rt_rw'})}
                                                    name="rw"
                                                    type="text"
                                                    minLength="3"
                                                    maxLength="3"
                                                    pattern="^[0-9]*$"
                                                    required
                                                    value={values.rw}
                                                    handleChange={handleChange}
                                                    isValid={touched.rw && !errors.rw}
                                                    isInvalid={!!errors.rw}
                                                    error={(typeof errors.rw === 'object') ? store.dispatch({type:'translate', 'text': errors.rw.type}).toString().replace('$param$', errors.rw.param).replace('$param2$', errors.rw.param2) : store.dispatch({type:'translate', 'text': errors.rw})}
                                                />
                                            <CustomInput 
                                                col="4"
                                                labelName={store.dispatch({type:'translate', 'text': 'postal_code'})}
                                                name="postal_code"
                                                type="text"
                                                maxLength="5"
                                                pattern="^[0-9]*$"
                                                required
                                                value={values.postal_code}
                                                handleChange={handleChange}
                                                isValid={touched.postal_code && !errors.postal_code}
                                                isInvalid={!!errors.postal_code}
                                                error={(typeof errors.postal_code === 'object') ? store.dispatch({type:'translate', 'text': errors.postal_code.type}).toString().replace('$param$', errors.postal_code.param).replace('$param2$', errors.postal_code.param2) : store.dispatch({type:'translate', 'text': errors.postal_code})}
                                            />                                                
                                            </Form.Row>
                                            : ''
                                        }

                                        <Form.Row>
                                            <CustomInput 
                                                col="6"
                                                labelName={store.dispatch({type:'translate', 'text': 'email'})}
                                                name="email_address"
                                                type="email"
                                                required
                                                value={values.email_address}
                                                handleChange={handleChange}
                                                isValid={touched.email_address && !errors.email_address}
                                                isInvalid={!!errors.email_address}
                                                error={(typeof errors.email_address === 'object') ? store.dispatch({type:'translate', 'text': errors.email_address.type}).toString().replace('$param$', errors.email_address.param).replace('$param2$', errors.email_address.param2) : store.dispatch({type:'translate', 'text': errors.email_address})}
                                            />
                                        </Form.Row>

                                        <Form.Row>
                                            <CustomInput
                                                col="6"
                                                labelName={store.dispatch({type:'translate', 'text': 'home_phone'})}
                                                info={store.dispatch({type:'translate', 'text': 'home_phone_info'})}
                                                group
                                                type="text"
                                                pattern="^[ 0-9]*$"
                                                handleChange={handleChange}
                                                input={[
                                                    {
                                                        name: 'home_phone_area_code',
                                                        value: values.home_phone_area_code,
                                                        maxLength: '4',
                                                        minLength: '2',
                                                        placeholder: '999',
                                                        isValid: touched.home_phone_area_code && !errors.home_phone_area_code,
                                                        isInvalid: !!errors.home_phone_area_code,
                                                        error: (typeof errors.home_phone_area_code === 'object') ? store.dispatch({type:'translate', 'text': errors.home_phone_area_code.type}).toString().replace('$param$', errors.home_phone_area_code.param).replace('$param2$', errors.home_phone_area_code.param2) : store.dispatch({type:'translate', 'text': errors.home_phone_area_code}),
                                                        className: 'area-code',
                                                        handleKeyUp: (ev) => {
                                                            ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                                        }
                                                    },
                                                    {
                                                        name: 'home_phone',
                                                        value: values.home_phone,
                                                        maxLength: '15',
                                                        placeholder: '999 999 999',
                                                        isValid: touched.home_phone && !errors.home_phone,
                                                        isInvalid: !!errors.home_phone,
                                                        error: (typeof errors.home_phone === 'object') ? store.dispatch({type:'translate', 'text': errors.home_phone.type}).toString().replace('$param$', errors.home_phone.param).replace('$param2$', errors.home_phone.param2) : store.dispatch({type:'translate', 'text': errors.home_phone}),
                                                        className: 'field-number',
                                                        handleKeyUp: (ev) => {
                                                            ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
                                                        }
                                                    },

                                                ]}
                                            />
                                            <CustomInput
                                                col="6"
                                                labelName={store.dispatch({type:'translate', 'text': 'mobile_number'})}
                                                name="mobile_phone"
                                                type="phonenumber"
                                                maxLength="15"
                                                pattern="^[ 0-9]*$"
                                                required
                                                value={values.mobile_phone}
                                                error={(typeof errors.mobile_phone === 'object') ? store.dispatch({type:'translate', 'text': errors.mobile_phone.type}).toString().replace('$param$', errors.mobile_phone.param).replace('$param2$', errors.mobile_phone.param2) : store.dispatch({type:'translate', 'text': errors.mobile_phone})}
                                                handleChange={(valid, value, code) => {
                                                    setFieldValue('mobile_phone', value)
                                                    setFieldValue('mobile_phone_country_code', code.iso2)
                                                    setFieldValue('mobile_phone_country_dialcode', code.dialCode)
                                                }}
                                                handleBlur={(valid) => {
                                                    setFieldTouched('mobile_phone', valid)
                                                }}
                                                handleSelectFlag={(code) => {
                                                    setFieldValue('mobile_phone_country_code', code.iso2)
                                                    setFieldValue('mobile_phone_country_dialcode', code.dialCode)
                                                }}
                                                defaultCountry={values.mobile_phone_country_code}
                                                preferredCountries={['id','sg','my','th','au','jp','kr','cn']}
                                            />
                                        </Form.Row>

                                        {/* HREL / Kontak Darurat */}
                                        <Form.Group className='valid'>
                                            <Form.Label>
                                                {store.dispatch({type:'translate', 'text': 'hrel_title'})}
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Row>
                                            {/* <CustomInput 
                                                col="12"
                                                labelName={store.dispatch({type:'translate', 'text': 'hrel_title'})}
                                                required
                                                readOnly
                                                type="text"
                                                isValid={true}
                                            /> */}
                                            <CustomInput 
                                                col="6"
                                                labelName={store.dispatch({type:'translate', 'text': 'hrel_name'})}
                                                required
                                                name="hrel_name"
                                                type="text"
                                                maxLength="100"
                                                pattern="^[A-Z a-z.,'&quot;]+"
                                                info={store.dispatch({type:'translate', 'text': 'hrel_name_help'})}
                                                value={values.hrel_name}
                                                handleChange={(el) => {
                                                    let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                    setFieldValue("hrel_name", elVal);
                                                }}
                                                isValid={touched.hrel_name && !errors.hrel_name}
                                                isInvalid={!!errors.hrel_name}
                                                error={(typeof errors.hrel_name === 'object') ? store.dispatch({type:'translate', 'text': errors.hrel_name.type}).toString().replace('$param$', errors.hrel_name.param).replace('$param2$',errors.hrel_name.param2) : store.dispatch({type:'translate', 'text': errors.hrel_name})}
                                            />
                                            <CustomInput
                                                col="6"
                                                labelName={store.dispatch({type:'translate', 'text': 'hrel_telp'})}
                                                info={store.dispatch({type:'translate', 'text': 'hrel_telp_info'})}
                                                name="hrel_telp"
                                                type="phonenumber"
                                                maxLength="15"
                                                pattern="^[ 0-9]*$"
                                                required
                                                value={values.hrel_telp}
                                                error={(typeof errors.hrel_telp === 'object') ? store.dispatch({type:'translate', 'text': errors.hrel_telp.type}).toString().replace('$param$', errors.hrel_telp.param).replace('$param2$', errors.hrel_telp.param2) : store.dispatch({type:'translate', 'text': errors.hrel_telp})}
                                                handleChange={(valid, value, code) => {
                                                    setFieldValue('hrel_telp', value)
                                                    setFieldValue('hrel_telp_country_code', code.iso2)
                                                    setFieldValue('hrel_telp_country_dialcode', code.dialCode)
                                                }}
                                                handleBlur={(valid) => {
                                                    setFieldTouched('hrel_telp', valid)
                                                }}
                                                handleSelectFlag={(code) => {
                                                    setFieldValue('hrel_telp_country_code', code.iso2)
                                                    setFieldValue('hrel_telp_country_dialcode', code.dialCode)
                                                }}
                                                defaultCountry={values.hrel_telp_country_code}
                                                preferredCountries={['id','sg','my','th','au','jp','kr','cn']}
                                            />
                                            <CustomInput 
                                                col="12"
                                                labelName={store.dispatch({type:'translate', 'text': 'hrel_address'})}
                                                info={store.dispatch({type:'translate', 'text': 'hrel_address_help'})}
                                                name="hrel_address"
                                                type="textarea"
                                                required
                                                maxLength="40"
                                                value={values.hrel_address}
                                                handleChange={(value) => {
                                                    let valUppercase = value ? value.toUpperCase() : "";
                                                    setFieldValue("hrel_address", valUppercase);
                                                }}
                                                isValid={touched.hrel_address && !errors.hrel_address}
                                                isInvalid={!!errors.hrel_address}
                                                error={(typeof errors.hrel_address === 'object') ? store.dispatch({type:'translate', 'text': errors.hrel_address.type}).toString().replace('$param$', errors.hrel_address.param).replace('$param2$', errors.hrel_address.param2) : store.dispatch({type:'translate', 'text': errors.hrel_address})}
                                            />
                                            <CustomInput 
                                                col="6"
                                                labelName={store.dispatch({type:'translate', 'text': 'hrell_type'})}
                                                required
                                                name="hrell_type"
                                                type="text"
                                                maxLength="100"
                                                pattern="^[A-Z a-z.,'&quot;]+"
                                                info={store.dispatch({type:'translate', 'text': 'hrell_type_info'})}
                                                value={values.hrell_type}
                                                handleChange={(el) => {
                                                    let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                    setFieldValue("hrell_type", elVal);
                                                }}
                                                isValid={touched.hrell_type && !errors.hrell_type}
                                                isInvalid={!!errors.hrell_type}
                                                error={(typeof errors.hrell_type === 'object') ? store.dispatch({type:'translate', 'text': errors.hrell_type.type}).toString().replace('$param$', errors.hrell_type.param).replace('$param2$',errors.hrell_type.param2) : store.dispatch({type:'translate', 'text': errors.hrell_type})}
                                            />
                                        </Form.Row>
                                        <div className={cx('row', styleRegis['wrap-btn'])}>
                                            <div className="container-btn col-md-12 text-center">
                                                <div className='button-control'>
                                                    <ButtonCustom class="yellow-btn" value={store.dispatch({type:'translate', 'text': 'btn_prev'})} onButtonClick={(value) => store.dispatch({type:'clickButton',field:'prev'})} />
                                                    <ButtonCustom id="contact-for-correspondence-btn" class="blue-btn" value={store.dispatch({type:'translate', 'text': 'btn_next'})} type="submit" loading={isSubmitting} disabled={isSubmitting}/>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" name="district"/>
                                        <input type="hidden" name="subdistrict"/>
                                    </Form>
                                </Fragment>
                                )}
                            </Formik>
                        )
                    }
                }
            </RootContext.Consumer>
		)
	}
}
export default props => (
    <RootContext.Consumer>
    {
        store => {
            return (
                <ContactForCorrespondenceForm {...props} cur_country={store.state.registration.country} cur_province={store.state.registration.province} master_province={store.state.master.master_province_ksei} master_city={store.state.master.master_city_ksei} cur_city={store.state.registration.city} cur_district_master={store.state.registration.district_master} />
            )
        }
    }
    </RootContext.Consumer>
)