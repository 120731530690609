import React, { Component, Fragment } from 'react';
import ButtonCustom from '../components/mini/ButtonCustom';
import Form from 'react-bootstrap/Form';
import validate from '../pages/validate';
import {RootContext} from '../Home';
import cx from 'classnames';
import styleRegis from '../pages/RegistrationPage.module.scss';
import { Formik } from 'formik';
import CustomInput from '../components/mini/CustomInput';
import * as Yup from "yup";
import axios from 'axios';
import $ from 'jquery';
import { isMobile } from "react-device-detect";

const link_api = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV;

function search(nameKey, myArray){
    if (myArray) {
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].value === nameKey) {
              return myArray[i];
            }
        }
    }
}

// ambil value dari key label pada object master data
function getTextLabelFromObjectMaster(valueToSearch, arrayObj) {
    // console.log(arrayObj);
    let resultOut = '';
    if(arrayObj) {
        for(let i=0;i < arrayObj.length; i++) {
            // console.log('value dari arrayObj: ' + arrayObj[i].value);
            // console.log('value dari valueToSearch: ' + valueToSearch);
            if(parseInt(arrayObj[i].value) === parseInt(valueToSearch)) {
                // console.log('true');
                resultOut = arrayObj[i].label; // ambil value dari key labelnya
            }
        }
    }

    // console.log('result: ' + resultOut);
    return resultOut;
}

function array_highlight(newSource, newHighlight) {
    let source = newSource;
    let highlight = newHighlight;
    let newArray = [];

    for (let k = 0; k < highlight.length; k++) {
        newArray.push(source.find(({value}) => value === highlight[k]))
    }

    // for (let i = 0; i < source.length; i++) {
    //     for (let j = 0; j < highlight.length; j++) {
    //         if (source[i].value !== highlight[j] && !newArray.find(({value}) => value === source[i].value)) {
    //             newArray.push(source[i]);
    //             break;
    //         }
    //     }
    // }
    return newArray;
}

class JobInformationForm extends Component {
	state = {
        masterCity: [],
        masterProvince: [],
        masterCityPos: [],
        masterCityPosCompany: [],
        masterJobPosition: [],
        masterFieldOfBusiness: [],
        valueOccupation: '',
        disableNpwpReason: false,
        masterCompanyDistrict:[],
        masterCompanySubDistrict: [],
        masterBoDistrict: [],
        masterBoSubDistrict: []
    }
    async onChangeOccupation(value) {
    	let masterJobPosition = [];
        try {
            let result = await axios.get(link_api + '/api/master-data', {params: { master_data: 'occupation_to_job_position', occupation_code: (typeof value === 'object') ? value.value : value}});
            if (result.data.status) {
				masterJobPosition = result.data.data.map((value) => { return  {value: parseInt(value.code), label: value.name}})
				this.setState({
					masterJobPosition: masterJobPosition,
					masterFieldOfBusiness: []
				});
			}
        } catch(e) {
            console.log(e);
        }

    }
    async onChangeJobPosition(value) {
    	let masterFieldOfBusiness = [];
		// axios.get(link_api + '/api/master-data', {params: { master_data: 'master_nob_bca', occupation_code: !isMobile ? $('input[name=occupation]').val() : $('select[name=occupation]').val(), job_position_code: (typeof value === 'object') ? value.value : value }}).then(result => {
		// 	if (result.data.status) {
		// 		masterFieldOfBusiness = result.data.data.map((value) => { return  {value: parseInt(value.code), label: value.name}})
		// 		this.setState({
		// 			masterFieldOfBusiness: masterFieldOfBusiness
		// 		});
		// 	}
		// }, (error) => {
		// 	console.log(error);
		// });
        
        try {
            let result = await axios.get(link_api + '/api/master-data', {params: { master_data: 'master_nob_bca', occupation_code: !isMobile ? $('input[name=occupation]').val() : $('select[name=occupation]').val(), job_position_code: (typeof value === 'object') ? value.value : value }});
            if (result.data.status) {
				masterFieldOfBusiness = result.data.data.map((value) => { return  {value: parseInt(value.code), label: value.name}})
				this.setState({
					masterFieldOfBusiness: masterFieldOfBusiness
				});
			}
        } catch (e) {
            console.log(e);
        }
        
    }
    onChangeCountry(value) {
        this.setState({
            masterProvince: this.props.master_province.filter((curVal) => {
                                if (typeof value === 'object') {
                                    if (value.value !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                } else {
                                    if (parseInt(value) !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                }
                            })
        });
    }
    onChangeProvince(value) {
        this.setState({
            masterCity: this.props.master_city.filter((curVal) => {
                            if (typeof value === 'object') {
                                return curVal.province_id === value.value;
                            } else {
                                return curVal.province_id === parseInt(value);
                            }
                        })
        });
    }
    onChangeProvincePos(value) {
        this.setState({
            masterCityPos: this.props.master_city.filter((curVal) => {
                            if (typeof value === 'object') {
                                return curVal.province_id === value.value;
                            } else {
                                return curVal.province_id === parseInt(value);
                            }
                        })
        });
    }    
    onChangeProvincePosCompany(value) {
        this.setState({
            masterCityPosCompany: this.props.master_city.filter((curVal) => {
                            if (typeof value === 'object') {
                                return curVal.province_id === value.value;
                            } else {
                                return curVal.province_id === parseInt(value);
                            }
                        })
        });
    }

    // On Change City
    async onChangeCity(selectedCity) {
        let masterDistrictContainer = [];

        let districtResult = await axios.get(link_api + '/api/master-data', {
            params: {
                master_data: 'master_district_cimb',
                city_ksei_id: (typeof selectedCity === 'object') ? selectedCity.value : selectedCity
            }
        });

        if(districtResult.data.status) {
            masterDistrictContainer = districtResult.data.data.map((value) => {
                return {value: parseInt(value.id), label: value.name}
            });
            this.setState({
                masterCompanyDistrict: masterDistrictContainer
            });
        }
    }

    async onChangeCompanyDistrict(selectedDistrict) {
        let masterSubDistrictContainer = [];
        let subDistrictResult = await axios.get(link_api + '/api/master-data', {
            params: {
                master_data: 'master_villages_cimb',
                district_cimb: (typeof selectedDistrict === 'object') ? selectedDistrict.value : selectedDistrict
            }
        });

        if(subDistrictResult.data.status) {
            masterSubDistrictContainer = subDistrictResult.data.data.map((value) => {
                return {value: parseInt(value.id), label: value.name}
            });
            this.setState({
                masterCompanySubDistrict: masterSubDistrictContainer
            });
        }

    }

    // On Change Beneficial Owner City
    async onChangeBoCity(selectedCity) {
        let masterDistrictContainer = [];

        let districtResult = await axios.get(link_api + '/api/master-data', {
            params: {
                master_data: 'master_district_cimb',
                city_ksei_id: (typeof selectedCity === 'object') ? selectedCity.value : selectedCity
            }
        });

        if(districtResult.data.status) {
            masterDistrictContainer = districtResult.data.data.map((value) => {
                return {value: parseInt(value.id), label: value.name}
            });
            this.setState({
                masterBoDistrict: masterDistrictContainer
            });
        }
    }

    // On Change Beneficial Owner District    
    async onChangeBoDistrict(selectedDistrict) {
        let masterSubDistrictContainer = [];
        let subDistrictResult = await axios.get(link_api + '/api/master-data', {
            params: {
                master_data: 'master_villages_cimb',
                district_cimb: (typeof selectedDistrict === 'object') ? selectedDistrict.value : selectedDistrict
            }
        });

        if(subDistrictResult.data.status) {
            masterSubDistrictContainer = subDistrictResult.data.data.map((value) => {
                return {value: parseInt(value.id), label: value.name}
            });
            this.setState({
                masterBoSubDistrict: masterSubDistrictContainer
            });
        }

    }
    async componentDidMount() {
        if (this.props.cur_country) {
            this.setState({
                masterProvince: this.props.master_province.filter((curVal) => {
                                if (typeof this.props.cur_country === 'object') {
                                    if (this.props.cur_country.value !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                } else {
                                    if (parseInt(this.props.cur_country) !== 104) {
                                        return curVal.value === 1000;
                                    } else {
                                        return curVal.value !== 1000;
                                    }
                                }
                            })
            })
        }

        if (this.props.cur_province) {
            this.setState({
                masterCity: this.props.master_city.filter((curVal) => {
                                if (typeof this.props.cur_province === 'object') {
                                    return curVal.province_id === this.props.cur_province.value;
                                } else {
                                    return curVal.province_id === this.props.cur_province;
                                }
                            })
            });
        }

        // jika sudah ada city yang terpilih sebelumnya, maka load master data district
        if(this.props.cur_company_city) {
            let selectedCity = this.props.cur_company_city;
            let masterDistrictContainer = [];

            let districtResult = await axios.get(link_api + '/api/master-data', {
                params: {
                    master_data: 'master_district_cimb',
                    city_ksei_id: (typeof selectedCity === 'object') ? selectedCity.value : selectedCity
                }
            });
    
            if(districtResult.data.status) {
                masterDistrictContainer = districtResult.data.data.map((value) => {
                    return {value: parseInt(value.id), label: value.name}
                });
                this.setState({
                    masterCompanyDistrict: masterDistrictContainer
                });
            }            
        }

        // jika sudah ada district master yang terpilih sebelumnya, maka load master data subdistrict
        if(this.props.cur_company_district_master) {
            let selectedDistrict = this.props.cur_company_district_master;
            let masterSubDistrictContainer = [];
            let subDistrictResult = await axios.get(link_api + '/api/master-data', {
                params: {
                    master_data: 'master_villages_cimb',
                    district_cimb: (typeof selectedDistrict === 'object') ? selectedDistrict.value : selectedDistrict
                }
            });
    
            if(subDistrictResult.data.status) {
                masterSubDistrictContainer = subDistrictResult.data.data.map((value) => {
                    return {value: parseInt(value.id), label: value.name}
                });
                this.setState({
                    masterCompanySubDistrict: masterSubDistrictContainer
                });
            }
        }

        if (this.props.cur_province_pos) {
            this.setState({
                masterCityPos: this.props.master_city.filter((curVal) => {
                                if (typeof this.props.cur_province_pos === 'object') {
                                    return curVal.province_id === this.props.cur_province_pos.value;
                                } else {
                                    return curVal.province_id === this.props.cur_province_pos;
                                }
                            })
            });
        }           

        if (this.props.cur_province_pos_company) {
            this.setState({
                masterCityPosCompany: this.props.master_city.filter((curVal) => {
                                if (typeof this.props.cur_province === 'object') {
                                    return curVal.province_id === this.props.cur_province_pos_company.value;
                                } else {
                                    return curVal.province_id === this.props.cur_province_pos_company;
                                }
                            })
            });
        }

        // jika sudah ada city yang terpilih sebelumnya di Beneficial Owner
        if(this.props.cur_pos_city) {
            let selectedCity = this.props.cur_pos_city;
            let masterDistrictContainer = [];

            let districtResult = await axios.get(link_api + '/api/master-data', {
                params: {
                    master_data: 'master_district_cimb',
                    city_ksei_id: (typeof selectedCity === 'object') ? selectedCity.value : selectedCity
                }
            });
    
            if(districtResult.data.status) {
                masterDistrictContainer = districtResult.data.data.map((value) => {
                    return {value: parseInt(value.id), label: value.name}
                });
                this.setState({
                    masterBoDistrict: masterDistrictContainer
                });
            }            
        }

        // jika sudah ada district yang terpilih sebelumnya di Beneficial Owner        
        if(this.props.cur_pos_district_master) {
            let selectedDistrict = this.props.cur_pos_district_master;
            let masterSubDistrictContainer = [];
            let subDistrictResult = await axios.get(link_api + '/api/master-data', {
                params: {
                    master_data: 'master_villages_cimb',
                    district_cimb: (typeof selectedDistrict === 'object') ? selectedDistrict.value : selectedDistrict
                }
            });
    
            if(subDistrictResult.data.status) {
                masterSubDistrictContainer = subDistrictResult.data.data.map((value) => {
                    return {value: parseInt(value.id), label: value.name}
                });
                this.setState({
                    masterBoSubDistrict: masterSubDistrictContainer
                });
            }            
        }

        if (this.props.cur_occupation && parseInt(this.props.cur_occupation.value) !== 2 && parseInt(this.props.cur_occupation.value) !== 3 && parseInt(this.props.cur_occupation.value) !== 18) {
        	let masterJobPosition = [];
			// axios.get(link_api + '/api/master-data', {params: { master_data: 'master_job_position_bca', occupation_code: this.props.cur_occupation.value}}).then(result => {
			// 	if (result.data.status) {
			// 		masterJobPosition = result.data.data.map((value) => { return  {value: parseInt(value.code), label: value.name}})
			// 		this.setState({
			// 			masterJobPosition: masterJobPosition,
			// 			masterFieldOfBusiness: []
			// 		});
			// 	}
			// }, (error) => {
			// 	console.log(error);
			// });

            try {
                let result = await axios.get(link_api + '/api/master-data', {params: { master_data: 'mjpb', occupation_code: this.props.cur_occupation.value}}) ;
				if (result.data.status) {
					masterJobPosition = result.data.data.map((value) => { return  {value: parseInt(value.code), label: value.name}})
					this.setState({
						masterJobPosition: masterJobPosition,
						masterFieldOfBusiness: []
					});
				}
            } catch(e) {
                console.log(e);
            }

        }

        // if(this.state.valueOccupation === "1" || this.state.valueOccupation === "2" ) {
        //     this.setState({
        //         valueOccupation: (typeof this.state.valueOccupation === 'object') ? this.state.valueOccupation.value : this.state.valueOccupation
        //     });
        // } else {
        //     this.setState({
        //         valueOccupation: (typeof this.props.cur_occupation === 'object') ? this.props.cur_occupation.value : this.props.cur_occupation
        //     });
        // }

        this.setState({
            valueOccupation: (typeof this.props.cur_occupation === 'object') ? this.props.cur_occupation.value : this.props.cur_occupation
        });

        if (this.props.cur_job_position && this.props.cur_occupation && !isNaN(parseInt(this.props.cur_job_position))) {
            // console.log(parseInt(this.props.cur_job_position))
	    	let masterFieldOfBusiness = [];
			// axios.get(link_api + '/api/master-data', {params: { master_data: 'master_nob_bca', occupation_code: this.props.cur_occupation.value, job_position_code: this.props.cur_job_position }}).then(result => {
			// 	if (result.data.status) {
			// 		masterFieldOfBusiness = result.data.data.map((value) => { return  {value: parseInt(value.code), label: value.name}})
			// 		this.setState({
			// 			masterFieldOfBusiness: masterFieldOfBusiness
			// 		});
			// 	}
			// }, (error) => {
			// 	console.log(error);
			// });

            try {
                let result = await axios.get(link_api + '/api/master-data', {params: { master_data: 'master_nob_bca', occupation_code: this.props.cur_occupation.value, job_position_code: this.props.cur_job_position }});
                if (result.data.status) {
					masterFieldOfBusiness = result.data.data.map((value) => { return  {value: parseInt(value.code), label: value.name}})
					this.setState({
						masterFieldOfBusiness: masterFieldOfBusiness
					});
				}
            } catch(e) {
                console.log(e);
            }
        }
        // console.log(this.props.posNpwpNumber);
        if(this.props.posNpwpNumber) {
            this.setState({
                disableNpwpReason: true
            })
        }
    }
	render() {
		return (
            <RootContext.Consumer>
                {
                    store => {
                        // const countrySelected = [104,119,135,200,46];
                        const countrySelected = [104];
                        const masterCountry = array_highlight(store.state.master.master_country, countrySelected);

                        const schema = !isMobile ? Yup.object().shape({
                            occupation: Yup.object().required('error_valueMissing').nullable(),
                            job_position: Yup.string().when("occupation", {
                                is: occ => (occ && (occ.value !== 1 && occ.value !== 2)),
                                then: Yup.string().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            field_of_business: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value !== 1 && occ.value !== 2 && occ.value !== 3 && occ.value !== 18)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),

                            monthly_income_range: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value !== 1 && occ.value !== 2)),
                                then: Yup.object().required('error_valueMissing').nullable(),
                            }),

                            company_country: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value !== 2),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            company_province: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value !== 2),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            company_city: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value !== 2),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            company_district_master: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value !== 2),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            company_subdistrict_master: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value !== 2),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),                            
                            pos_province: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            pos_city: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            pos_district_master: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            pos_subdistrict_master: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),                                                        
                            pos_id_type: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            pos_occupation_code: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            pos_annual_income: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            pos_source_of_income: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),

                            pos_gender: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value === 1),
                                then: Yup.object().required('error_valueMissing').nullable(),
                            }),

                            pos_marital_status: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value === 1),
                                then: Yup.object().required('error_valueMissing').nullable(),
                            }),

                            pos_family_relationship: Yup.object().when("occupation", {
                                is: occ => (occ && occ.value === 1),
                                then: Yup.object().required('error_valueMissing').nullable(),
                            }),
                            
                            pos_no_npwp_reason: Yup.object().when(["occupation", "pos_npwp_number" ], {
                                is: (occupation, pos_npwp_number) => (!pos_npwp_number && (typeof occupation === 'object' ? occupation.value === 1 || occupation.value === 2 : occupation === 1 || occupation === 2 )),
                                then: Yup.object().required('error_valueMissing').nullable(),
                            }),

                            pos_company_province: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            pos_company_city: Yup.object().when("occupation", {
                                is: occ => (occ && (occ.value === 1 || occ.value === 2)),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),                            

                        })
                        : '';
                    	return (
                    		<Formik
                                validationSchema={schema}
                                validate={(values) => validate(values, '#job-information')} 
                                enableReinitialize
                                onSubmit={values => {
                                    store.dispatch({type:'handleSubmit',field:'registration_job_information', values: values})
                                }}
                                initialValues={{
                                    occupation: store.state.registration.occupation ? store.state.registration.occupation : '',
                                    // nature_of_business: store.state.registration.nature_of_business,
                                    // occupation_others: store.state.registration.occupation_others,
                                    job_position: store.state.registration.job_position ? (search(parseInt(store.state.registration.job_position), this.state.masterJobPosition) === undefined) ? store.state.registration.job_position : search(parseInt(store.state.registration.job_position), this.state.masterJobPosition) : '',

                                    field_of_business: store.state.registration.field_of_business ? (search(parseInt(store.state.registration.field_of_business), this.state.masterFieldOfBusiness) === undefined) ? store.state.registration.field_of_business : search(parseInt(store.state.registration.field_of_business), this.state.masterFieldOfBusiness) : '',

                                    monthly_income_range: store.state.registration.monthly_income_range,

                                    company_name: store.state.registration.company_name,
                                    company_address: store.state.registration.company_address,
                                    company_country: store.state.registration.company_country,
                                    company_province: store.state.registration.company_province,
                                    company_city: store.state.registration.company_city,
                                    company_postal_code: store.state.registration.company_postal_code,
                                    company_phone_area_code: store.state.registration.company_phone_area_code,
                                    company_phone_number: store.state.registration.company_phone_number,

                                    pos_full_name: store.state.registration.pos_full_name,
                                    pos_place_of_birth: store.state.registration.pos_place_of_birth,
                                    pos_dob: store.state.registration.pos_dob,
                                    pos_home_phone: store.state.registration.pos_home_phone,
                                    pos_mobile_phone: store.state.registration.pos_mobile_phone,
                                    pos_address: store.state.registration.pos_address,
                                    pos_province: store.state.registration.pos_province,
                                    pos_city: store.state.registration.pos_city,
                                    pos_postalcode: store.state.registration.pos_postalcode,
                                    pos_id_type: store.state.registration.pos_id_type,
                                    pos_idcard_no: store.state.registration.pos_idcard_no,
                                    pos_idcard_expdate: store.state.registration.pos_idcard_expdate === '0001-01-01' ? '' : store.state.registration.pos_idcard_expdate,
                                    pos_occupation_code: store.state.registration.pos_occupation_code,
                                    pos_occupation_name: store.state.registration.pos_occupation_name,
                                    pos_company_name: store.state.registration.pos_company_name,
                                    pos_company_address: store.state.registration.pos_company_address,
                                    pos_company_province: store.state.registration.pos_company_province,
                                    pos_company_city: store.state.registration.pos_company_city,
                                    pos_company_postalcode: store.state.registration.pos_company_postalcode,
                                    pos_company_phone_number: store.state.registration.pos_company_phone_number,
                                    pos_company_fax: store.state.registration.pos_company_fax,
                                    pos_annual_income: store.state.registration.pos_annual_income,
                                    pos_source_of_income: store.state.registration.pos_source_of_income,

                                    work_year: store.state.registration.work_year,
                                    work_month: store.state.registration.work_month,

                                    pos_gender: store.state.registration.pos_gender,
                                    pos_marital_status: store.state.registration.pos_marital_status,
                                    pos_family_relationship: store.state.registration.pos_marital_status,

                                    pos_email_address: store.state.registration.pos_email_address,
                                    pos_rt: store.state.registration.pos_rt,
                                    pos_rw: store.state.registration.pos_rw,
                                    pos_district: store.state.registration.pos_district,
                                    pos_subdistrict: store.state.registration.pos_subdistrict,

                                    pos_npwp_number: store.state.registration.pos_npwp_number,
                                    pos_no_npwp_reason: store.state.registration.pos_no_npwp_reason,

                                    company_rt: store.state.registration.company_rt,
                                    company_rw: store.state.registration.company_rw,
                                    company_subdistrict: store.state.registration.company_subdistrict,
                                    company_district: store.state.registration.company_district,

                                    company_district_master: store.state.registration.company_district_master,
                                    company_subdistrict_master: store.state.registration.company_subdistrict_master,

                                    pos_district_master: store.state.registration.pos_district_master,
                                    pos_subdistrict_master: store.state.registration.pos_subdistrict_master,

                                    pos_ktp_file: store.state.registration.pos_ktp_file,
                                    pos_npwp_file: store.state.registration.pos_npwp_file,
                                }}
                                >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    isSubmitting,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched,
                                }) => (
                                <Fragment>
                                    <Form id="job-information" className="form" validated={errors ? false : true } noValidate onSubmit={handleSubmit}>
                                    	{
                                            /*
                                            <Form.Row>
                                                <pre
                                                  className="col-lg-12"
                                                  style={{
                                                    background: '#f6f8fa',
                                                    fontSize: '.65rem',
                                                    padding: '.5rem',
                                                    textAlign: 'left',
                                                  }}
                                                >
                                                  <strong>props</strong> ={' '}
                                                  {JSON.stringify(values, null, 2)}
                                                  <br/>
                                                  <strong>errors</strong> ={' '}
                                                  {JSON.stringify(errors, null, 2)}
                                                  <br/>
                                                  <strong>touched</strong> ={' '}
                                                  {JSON.stringify(touched, null, 2)}
                                                  <br/>
                                                </pre>
                                            </Form.Row>
                                            */
                                        }
                                        <Form.Row>
                                            <CustomInput 
                                                col="4"
                                                labelName={store.dispatch({type:'translate', 'text': 'occupation'})}
                                                name="occupation"
                                                type="select"
                                                required
                                                value={values.occupation}
                                                onChange={(name,value) => {
                                                    this.onChangeOccupation(value);
                                                    setFieldValue(name, value);
                                                    setFieldValue('job_position', '');
                                                    setFieldValue('field_of_business', '');
                                                    this.setState({
                                                        valueOccupation: (typeof value === 'object') ? value.value : value
                                                    })
                                                }}
                                                onBlur={setFieldTouched}
                                                placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                error={(typeof errors.occupation === 'object') ? store.dispatch({type:'translate', 'text': errors.occupation.type}).toString().replace('$param$', errors.occupation.param).replace('$param2$', errors.occupation.param2) : store.dispatch({type:'translate', 'text': errors.occupation})}
                                                touched={touched.occupation}
                                                option={store.state.master.master_occupation_bca}
                                            />
                                            {
                                            	((typeof values.occupation === 'object' && (values.occupation.value === 2 || values.occupation.value === 1)) || parseInt(values.occupation) === 2 ) || parseInt(values.occupation) === 1 ?
                                            	''
                                            	:
                                            	((typeof values.occupation === 'object' && (values.occupation.value === 3 || values.occupation.value === 18)) || (parseInt(values.occupation) === 3 || parseInt(values.occupation) === 18)) ?
                                            	<Fragment>
		                                            <CustomInput 
		                                                col="4"
		                                                labelName={store.dispatch({type:'translate', 'text': 'job_position'})}
		                                                name="job_position"
		                                                type="text"
		                                                maxLength="100"
		                                                pattern="^[A-Z a-z]+"
		                                                value={values.job_position}
		                                                handleChange={(el) => {
                                                            let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                            setFieldValue("job_position", elVal);
                                                        }}
		                                                isValid={touched.job_position && !errors.job_position}
		                                                isInvalid={!!errors.job_position}
		                                                error={(typeof errors.job_position === 'object') ? store.dispatch({type:'translate', 'text': errors.job_position.type}).toString().replace('$param$', errors.job_position.param).replace('$param2$', errors.job_position.param2) : store.dispatch({type:'translate', 'text': errors.job_position})}
		                                            />
                                            	</Fragment>
                                            	:
                                            	<Fragment>
		                                            <CustomInput 
		                                                col="4"
		                                                labelName={store.dispatch({type:'translate', 'text': 'job_position'})}
		                                                name="job_position"
		                                                type="select"
		                                                required
		                                                value={values.job_position}
		                                                onChange={(name,value) => {
		                                                    this.onChangeJobPosition(value);
		                                                    setFieldValue(name, value);
		                                                }}
		                                                onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
		                                                error={(typeof errors.job_position === 'object') ? store.dispatch({type:'translate', 'text': errors.job_position.type}).toString().replace('$param$', errors.job_position.param).replace('$param2$', errors.job_position.param2) : store.dispatch({type:'translate', 'text': errors.job_position})}
		                                                touched={touched.job_position}
		                                                option={this.state.masterJobPosition}
		                                            />
		                                            <CustomInput 
		                                                col="4"
		                                                labelName={store.dispatch({type:'translate', 'text': 'field_of_business'})}
		                                                name="field_of_business"
		                                                type="select"
		                                                required
		                                                value={values.field_of_business}
		                                                onChange={setFieldValue}
		                                                onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
		                                                error={(typeof errors.field_of_business === 'object') ? store.dispatch({type:'translate', 'text': errors.field_of_business.type}).toString().replace('$param$', errors.field_of_business.param).replace('$param2$', errors.field_of_business.param2) : store.dispatch({type:'translate', 'text': errors.field_of_business})}
		                                                touched={touched.field_of_business}
		                                                option={this.state.masterFieldOfBusiness}
		                                            />
	                                            </Fragment>
                                                
                                            }
                                        </Form.Row>
                                        {/* jika bukan ibu rumah tangga */}
                                        {
                                            (this.state.valueOccupation && parseInt(this.state.valueOccupation) !== 2) ?
                                            <Fragment>
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={ (parseInt(this.state.valueOccupation ) === 1) ? store.dispatch({type:'translate', 'text': 'institution_name'}) : store.dispatch({type:'translate', 'text': 'company_name'})}
                                                        name="company_name"
                                                        type="text"
                                                        required
                                                        maxLength="100"
                                                        // pattern="^[A-Z a-z]+"
                                                        value={values.company_name}
                                                        handleChange={(el) => {
                                                            let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                            setFieldValue("company_name", elVal);
                                                        }}
                                                        isValid={touched.company_name && !errors.company_name}
                                                        isInvalid={!!errors.company_name}
                                                        error={(typeof errors.company_name === 'object') ? store.dispatch({type:'translate', 'text': errors.company_name.type}).toString().replace('$param$', errors.company_name.param).replace('$param2$', errors.company_name.param2) : store.dispatch({type:'translate', 'text': errors.company_name})}
                                                    />
                                                </Form.Row>

                                                {/* jika bukan pelajar, maka tampilkan monthly income dan work period */}
                                                {
                                                (this.state.valueOccupation && parseInt(this.state.valueOccupation) !== 1) ?
                                                <Fragment>
                                                <Form.Row>
                                                    <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({ type: 'translate', 'text': 'monthly_income_range' })}
                                                        name="monthly_income_range"
                                                        type="select"
                                                        required
                                                        value={values.monthly_income_range}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}

                                                        error={(typeof errors.monthly_income_range === 'object') ? store.dispatch({ type: 'translate', 'text': errors.monthly_income_range.type }).toString().replace('$param$', errors.monthly_income_range.param).replace('$param2$', errors.monthly_income_range.param) : store.dispatch({ type: 'translate', 'text': errors.monthly_income_range })}

                                                        touched={touched.monthly_income_range}
                                                        option={store.state.master.master_monthly_income_range}
                                                    />                                                    
                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'work_period'})}
                                                        info="Tahun - Bulan"
                                                        group
                                                        type="text"
                                                        pattern="^[0-9]*$"
                                                        handleChange={handleChange}
                                                        required
                                                        input={[
                                                            {
                                                                name: 'work_year',
                                                                value: values.work_year,
                                                                required: true,
                                                                maxLength: '2',
                                                                minLength: '1',
                                                                placeholder: store.dispatch({'type':'translate', 'text':'work_period_year'}),
                                                                isValid: touched.work_year && !errors.work_year,
                                                                isInvalid: !!errors.work_year,
                                                                error: (typeof errors.work_year === 'object') ? store.dispatch({type:'translate', 'text': errors.work_year.type}).toString().replace('$param$', errors.work_year.param).replace('$param2$', errors.work_year.param2) : store.dispatch({type:'translate', 'text': errors.work_year}),
                                                                className: 'work-period-field',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^\d]/g, '');
                                                                }
                                                            },
                                                            {
                                                                name: 'work_month',
                                                                value: values.work_month,
                                                                maxLength: '2',
                                                                minLength: '1',
                                                                placeholder: store.dispatch({'type':'translate', 'text':'work_period_month'}),
                                                                isValid: touched.work_month && !errors.work_month,
                                                                isInvalid: !!errors.work_month,
                                                                error: (typeof errors.work_month === 'object') ? store.dispatch({type:'translate', 'text': errors.work_month.type}).toString().replace('$param$', errors.work_month.param).replace('$param2$', errors.work_month.param2) : store.dispatch({type:'translate', 'text': errors.work_month}),
                                                                className: 'work-period-field',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^\d]/g, '').replace(/^(1[^0-2]|[2-9][0-9]|[^1-9])$/g, '');
                                                                }
                                                            },
                                                    
                                                        ]}
                                                    />                                                                                                 
                                                </Form.Row>
                                                </Fragment>
                                                : ''
                                                }

                                                {/** Detail alamat perusahaan, seperti nama jalan, kampung dan sejenisnya */}
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="12"
                                                        labelName={(parseInt(this.state.valueOccupation ) === 1) ? store.dispatch({type:'translate', 'text': 'institution_address'}) : store.dispatch({type:'translate', 'text': 'company_address'})}
                                                        info="Nama Jalan/Kampung/Nama Gedung/Nomor Lantai atau Bangunan dan sejenisnya"
                                                        name="company_address"
                                                        type="textarea"
                                                        required
                                                        maxLength="40"
                                                        value={values.company_address}
                                                        handleChange={(value) => {
                                                            let valUppercase = value ? value.toUpperCase() : "";
                                                            setFieldValue("company_address", valUppercase);
                                                        }}
                                                        isValid={touched.company_address && !errors.company_address}
                                                        isInvalid={!!errors.company_address}
                                                        error={(typeof errors.company_address === 'object') ? store.dispatch({type:'translate', 'text': errors.company_address.type}).toString().replace('$param$', errors.company_address.param).replace('$param2$', errors.company_address.param2) : store.dispatch({type:'translate', 'text': errors.company_address})}
                                                    />
                                                </Form.Row>                                                

                                                {/** Negara, provinsi dan kota */}
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'country'})}
                                                        name="company_country"
                                                        type="select"
                                                        required
                                                        value={values.company_country}
                                                        // onChange={setFieldValue}
                                                        onChange={(name,value) => {
                                                            this.onChangeCountry(value);
                                                            setFieldValue(name, value);
                                                            setFieldValue('company_province', '');
                                                            setFieldValue('company_city', '');
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.company_country === 'object') ? store.dispatch({type:'translate', 'text': errors.company_country.type}).toString().replace('$param$', errors.company_country.param).replace('$param2$', errors.company_country.param2) : store.dispatch({type:'translate', 'text': errors.company_country})}
                                                        touched={touched.company_country}
                                                        option={masterCountry}
                                                        gap={countrySelected[countrySelected.length - 1]}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'province'})}
                                                        name="company_province"
                                                        type="select"
                                                        required
                                                        value={values.company_province}
                                                        // onChange={setFieldValue}
                                                        onChange={(name,value) => {
                                                            setFieldValue('company_city', '');
                                                            setFieldValue('company_district_master', '');
                                                            setFieldValue('company_subdistrict_master', 
                                                            '');

                                                            setFieldValue('company_district', '');
                                                            setFieldValue('company_subdistrict', '');

                                                            this.onChangeProvince(value);
                                                            setFieldValue(name, value);                                                            
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.company_province === 'object') ? store.dispatch({type:'translate', 'text': errors.company_province.type}).toString().replace('$param$', errors.company_province.param).replace('$param2$', errors.company_province.param2) : store.dispatch({type:'translate', 'text': errors.company_province})}
                                                        touched={touched.company_province}
                                                        option={this.state.masterProvince}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'city'})}
                                                        name="company_city"
                                                        type="select"
                                                        required
                                                        value={values.company_city}
                                                        onChange={(name, value) => {
                                                            setFieldValue('company_district_master', '');
                                                            setFieldValue('company_subdistrict_master', '');

                                                            setFieldValue('company_district', '');
                                                            setFieldValue('company_subdistrict', '');

                                                            this.onChangeCity(value);
                                                            setFieldValue(name, value);
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.company_city === 'object') ? store.dispatch({type:'translate', 'text': errors.company_city.type}).toString().replace('$param$', errors.company_city.param).replace('$param2$', errors.company_city.param2) : store.dispatch({type:'translate', 'text': errors.company_city})}
                                                        touched={touched.company_city}
                                                        option={this.state.masterCity}
                                                    />
                                                </Form.Row>

                                                {/* Kecamatan dan Kelurahan dari Master Data */}
                                                <Form.Row>
                                                <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'district'})}
                                                        name="company_district_master"
                                                        type="select"
                                                        required
                                                        value={values.company_district_master}
                                                        onChange={(name,value) => {
                                                            // console.log(value);
                                                            setFieldValue('company_subdistrict_master', '');
                                                            setFieldValue('company_subdistrict', '');

                                                            let _currentValue = (typeof value === 'object') ? value.value : value;
                                                            let _textLabel = getTextLabelFromObjectMaster(_currentValue, this.state.masterCompanyDistrict);
                                                            // console.log(_textLabel);
                                                            
                                                            let districtTextValue = (typeof value === 'object') ? value.label : _textLabel; // mobile dan browser beda cara ambil label name
                                                            setFieldValue('company_district', districtTextValue);
                                                            this.onChangeCompanyDistrict(value);
                                                            setFieldValue(name, value);
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.company_district_master === 'object') ? store.dispatch({type:'translate', 'text': errors.company_district_master.type}).toString().replace('$param$', errors.company_district_master.param).replace('$param2$', errors.company_district_master.param2) : store.dispatch({type:'translate', 'text': errors.company_district_master})}
                                                        touched={touched.company_district_master}
                                                        option={this.state.masterCompanyDistrict}
                                                    />
                                                <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'subdistrict'})}
                                                        name="company_subdistrict_master"
                                                        type="select"
                                                        required
                                                        value={values.company_subdistrict_master}
                                                        onChange={(name,value) => {

                                                            let _currentValue = (typeof value === 'object') ? value.value : value;
                                                            let _textLabel = getTextLabelFromObjectMaster(_currentValue, this.state.masterCompanySubDistrict);
                                                            
                                                            let districtTextValue = (typeof value === 'object') ? value.label : _textLabel; // mobile dan browser beda cara ambil label name
                                                            setFieldValue('company_subdistrict', districtTextValue);
                                                            setFieldValue(name, value);
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.company_subdistrict_master === 'object') ? store.dispatch({type:'translate', 'text': errors.company_subdistrict_master.type}).toString().replace('$param$', errors.company_subdistrict_master.param).replace('$param2$', errors.company_subdistrict_master.param2) : store.dispatch({type:'translate', 'text': errors.company_subdistrict_master})}
                                                        touched={touched.company_subdistrict_master}
                                                        option={this.state.masterCompanySubDistrict}
                                                    />                                            
                                                </Form.Row>
                                                {/* End Kecamatan dan Kelurahan dari Master Data */}

                                                {/* Company RT, RW, Kelurahan, Kecamatan */}
                                                <Form.Row>                                                 
                                                {/* <CustomInput 
                                                    col="3"
                                                    labelName={store.dispatch({type:'translate', 'text': 'district'})}
                                                    name="company_district"
                                                    type="text"
                                                    minLength="1"
                                                    // pattern="^[0-9]*$"
                                                    required
                                                    value={values.company_district}
                                                    handleChange={handleChange}
                                                    isValid={touched.company_district && !errors.company_district}
                                                    isInvalid={!!errors.company_district}
                                                    error={(typeof errors.company_district === 'object') ? store.dispatch({type:'translate', 'text': errors.company_district.type}).toString().replace('$param$', errors.company_district.param).replace('$param2$', errors.company_district.param2) : store.dispatch({type:'translate', 'text': errors.company_district})}
                                                />

                                                <CustomInput 
                                                    col="3"
                                                    labelName={store.dispatch({type:'translate', 'text': 'subdistrict'})}
                                                    name="company_subdistrict"
                                                    type="text"
                                                    minLength="1"
                                                    // pattern="^[0-9]*$"
                                                    required
                                                    value={values.company_subdistrict}
                                                    handleChange={handleChange}
                                                    isValid={touched.company_subdistrict && !errors.company_subdistrict}
                                                    isInvalid={!!errors.company_subdistrict}
                                                    error={(typeof errors.company_subdistrict === 'object') ? store.dispatch({type:'translate', 'text': errors.company_subdistrict.type}).toString().replace('$param$', errors.company_subdistrict.param).replace('$param2$', errors.company_subdistrict.param2) : store.dispatch({type:'translate', 'text': errors.company_subdistrict})}
                                                />                                                    */}

                                                <CustomInput 
                                                    col="3"
                                                    labelName="RT"
                                                    info={store.dispatch({type:'translate', 'text': 'info_rt_rw'})}
                                                    name="company_rt"
                                                    type="text"
                                                    minLength="3"
                                                    maxLength="3"
                                                    pattern="^[0-9]*$"
                                                    required
                                                    value={values.company_rt}
                                                    handleChange={handleChange}
                                                    isValid={touched.pos_rt && !errors.company_rt}
                                                    isInvalid={!!errors.company_rt}
                                                    error={(typeof errors.company_rt === 'object') ? store.dispatch({type:'translate', 'text': errors.company_rt.type}).toString().replace('$param$', errors.company_rt.param).replace('$param2$', errors.company_rt.param2) : store.dispatch({type:'translate', 'text': errors.company_rt})}
                                                />
                                                <CustomInput 
                                                    col="3"
                                                    labelName="RW"
                                                    info={store.dispatch({type:'translate', 'text': 'info_rt_rw'})}
                                                    name="company_rw"
                                                    type="text"
                                                    minLength="3"
                                                    maxLength="3"
                                                    pattern="^[0-9]*$"
                                                    required
                                                    value={values.company_rw}
                                                    handleChange={handleChange}
                                                    isValid={touched.company_rw && !errors.company_rw}
                                                    isInvalid={!!errors.company_rw}
                                                    error={(typeof errors.company_rw === 'object') ? store.dispatch({type:'translate', 'text': errors.company_rw.type}).toString().replace('$param$', errors.company_rw.param).replace('$param2$', errors.company_rw.param2) : store.dispatch({type:'translate', 'text': errors.company_rw})}
                                                />                                                      

                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'postal_code'})}
                                                        name="company_postal_code"
                                                        type="text"
                                                        maxLength="5"
                                                        pattern="^[0-9]*$"
                                                        required
                                                        value={values.company_postal_code}
                                                        handleChange={handleChange}
                                                        isValid={touched.company_postal_code && !errors.company_postal_code}
                                                        isInvalid={!!errors.company_postal_code}
                                                        error={(typeof errors.company_postal_code === 'object') ? store.dispatch({type:'translate', 'text': errors.company_postal_code.type}).toString().replace('$param$', errors.company_postal_code.param).replace('$param2$', errors.company_postal_code.param2) : store.dispatch({type:'translate', 'text': errors.company_postal_code})}
                                                    />
                                                    <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'home_phone'})}
                                                        info={store.dispatch({type:'translate', 'text': 'home_phone_info'})}
                                                        group
                                                        type="text"
                                                        pattern="^[ 0-9]*$"
                                                        required
                                                        handleChange={handleChange}
                                                        input={[
                                                            {
                                                                name: 'company_phone_area_code',
                                                                value: values.company_phone_area_code,
                                                                maxLength: '4',
                                                                minLength: '2',
                                                                placeholder: '999',
                                                                isValid: touched.company_phone_area_code && !errors.company_phone_area_code,
                                                                isInvalid: !!errors.company_phone_area_code,
                                                                error: (typeof errors.company_phone_area_code === 'object') ? store.dispatch({type:'translate', 'text': errors.company_phone_area_code.type}).toString().replace('$param$', errors.company_phone_area_code.param).replace('$param2$', errors.company_phone_area_code.param2) : store.dispatch({type:'translate', 'text': errors.company_phone_area_code}),
                                                                className: 'area-code',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                                                }
                                                            },
                                                            {
                                                                name: 'company_phone_number',
                                                                value: values.company_phone_number,
                                                                maxLength: '15',
                                                                placeholder: '999 999 999',
                                                                isValid: touched.company_phone_number && !errors.company_phone_number,
                                                                isInvalid: !!errors.company_phone_number,
                                                                error: (typeof errors.company_phone_number === 'object') ? store.dispatch({type:'translate', 'text': errors.company_phone_number.type}).toString().replace('$param$', errors.company_phone_number.param).replace('$param2$', errors.company_phone_number.param2) : store.dispatch({type:'translate', 'text': errors.company_phone_number}),
                                                                className: 'field-number',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
                                                                }
                                                            },

                                                        ]}
                                                    />
                                                </Form.Row>
                                            </Fragment>
                                            : ''
                                        }
                                        {
                                            (parseInt(this.state.valueOccupation) === 1 || parseInt(this.state.valueOccupation) === 2) ?
                                            <Fragment>
                                            <fieldset>
                                            <legend className="fieldset">{(parseInt(this.state.valueOccupation) === 1) ? store.dispatch({type:'translate', 'text': 'label_parent'}) : store.dispatch({type:'translate', 'text': 'spouse_name'})} <small className="info">{store.dispatch({type:'translate', 'text': 'info_fieldset'})}<span className="marked">*</span>{store.dispatch({type:'translate', 'text': 'info_fieldset_2'})}</small></legend>

                                                <Form.Row>
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={ store.dispatch({type:'translate', 'text': 'fullname'}) }
                                                        name="pos_full_name"
                                                        type="text"
                                                        required
                                                        maxLength="60"
                                                        // pattern="^[A-Z a-z]+"
                                                        value={values.pos_full_name}
                                                        handleChange={
                                                            (el) => {
                                                                let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                                setFieldValue("pos_full_name", elVal);
                                                            }
                                                        }
                                                        isValid={touched.pos_full_name && !errors.pos_full_name}
                                                        isInvalid={!!errors.pos_full_name}
                                                        error={(typeof errors.pos_full_name === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_full_name.type}).toString().replace('$param$', errors.pos_full_name.param).replace('$param2$', errors.pos_full_name.param2) : store.dispatch({type:'translate', 'text': errors.pos_full_name})}
                                                    />
                                                </Form.Row>

                                                {/* jika pelajar, tampilkan field gender, marital status dan family relationship pada data BO */}
                                                {
                                                    (parseInt(this.state.valueOccupation) === 1) ?
                                                    <Fragment>
                                                        <Form.Row>
                                                            <CustomInput 
                                                                    col="4"
                                                                    labelName={store.dispatch({type:'translate', 'text': 'gender'})}
                                                                    name="pos_gender"
                                                                    type="select"
                                                                    required
                                                                    option={store.state.master.sex}
                                                                    onChange={(name, value) => {
                                                                        setFieldValue(name, value);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                                    value={values.pos_gender}
                                                                    error={(typeof errors.pos_gender === 'object') ? store.dispatch({type:'translate', 'text':          errors.pos_gender.type}).toString().replace('$param$', errors.pos_gender.param).replace         ('$param2$', errors.pos_gender.param2) : store.dispatch({type:'translate', 'text': errors.pos_gender})}
                                                                    touched={touched.pos_gender}
                                                                />
                                                            <CustomInput 
                                                                    col="4"
                                                                    labelName={store.dispatch({type:'translate', 'text': 'marital_status'})}
                                                                    name="pos_marital_status"
                                                                    type="select"
                                                                    required
                                                                    option={store.state.master.master_marital_status_cimb}
                                                                    onChange={(name, value) => {
                                                                        setFieldValue(name, value);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                                    value={values.pos_marital_status}
                                                                    error={(typeof errors.pos_marital_status === 'object') ? store.dispatch({type:'translate','text': errors.pos_marital_status.type}).toString().replace('$param$', errors.pos_marital_status.param).replace('$param2$', errors.pos_marital_status.param2) : store.dispatch({type:'translate', 'text': errors.pos_marital_status})}
                                                                    touched={touched.pos_marital_status}
                                                                />
                                                            <CustomInput 
                                                                    col="4"
                                                                    labelName={store.dispatch({type:'translate', 'text': 'family_relationship'})}
                                                                    name="pos_family_relationship"
                                                                    type="select"
                                                                    required
                                                                    option={store.state.master.master_family_relationship_cimb}
                                                                    onChange={(name, value) => {
                                                                        setFieldValue(name, value);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                                    value={values.pos_family_relationship}
                                                                    error={(typeof errors.pos_family_relationship === 'object') ? store.dispatch({type:'translate','text': errors.pos_family_relationship.type}).toString().replace('$param$', errors.pos_family_relationship.param).replace('$param2$', errors.pos_family_relationship.param2) : store.dispatch({type:'translate', 'text': errors.pos_family_relationship})}
                                                                    touched={touched.pos_family_relationship}
                                                                />                                                                
                                                        </Form.Row>                                                        
                                                    </Fragment>
                                                    : ""
                                                }

                                                <Form.Row>
                                                <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'pob'})}
                                                        name="pos_place_of_birth"
                                                        type="text"
                                                        required
                                                        maxLength="100"
                                                        pattern="^[A-Z a-z]+"
                                                        value={values.pos_place_of_birth}
                                                        handleChange={
                                                            (el) => {
                                                                let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                                setFieldValue("pos_place_of_birth", elVal);
                                                            }
                                                        }
                                                        isValid={touched.pos_place_of_birth && !errors.pos_place_of_birth}
                                                        isInvalid={!!errors.pos_place_of_birth}
                                                        error={(typeof errors.pos_place_of_birth === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_place_of_birth.type}).toString().replace('$param$', errors.pos_place_of_birth.param).replace('$param2$', errors.pos_place_of_birth.param) : store.dispatch({type:'translate', 'text': errors.pos_place_of_birth})}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'dob'})}
                                                        required
                                                        name="pos_dob"
                                                        type="date"
                                                        dateType="birthdate"
                                                        value={values.pos_dob}
                                                        handleChange={date => setFieldValue('pos_dob', date)}
                                                        isValid={touched.pos_dob && !errors.pos_dob}
                                                        isInvalid={!!errors.pos_dob}
                                                        error={(typeof errors.pos_dob === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_dob.type}).toString().replace('$param$', errors.pos_dob.param).replace('$param2$', errors.pos_dob.param) : store.dispatch({type:'translate', 'text': errors.pos_dob})}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'home_phone'})}
                                                        // info={store.dispatch({type:'translate', 'text': 'home_phone_info'})}
                                                        group
                                                        type="text"
                                                        pattern="^[ 0-9]*$"
                                                        handleChange={handleChange}
                                                        input={[
                                                            {
                                                                name: 'pos_home_phone',
                                                                value: values.pos_home_phone,
                                                                maxLength: '25',
                                                                placeholder: '999 999 999',
                                                                isValid: touched.pos_home_phone && !errors.pos_home_phone,
                                                                isInvalid: !!errors.pos_home_phone,
                                                                error: (typeof errors.pos_home_phone === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_home_phone.type}).toString().replace('$param$', errors.pos_home_phone.param).replace('$param2$', errors.pos_home_phone.param2) : store.dispatch({type:'translate', 'text': errors.pos_home_phone}),
                                                                // className: 'field-number',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
                                                                }
                                                            },

                                                        ]}
                                                    />
                                                    <CustomInput
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'mobile_number'})}
                                                        // info={store.dispatch({type:'translate', 'text': 'home_phone_info'})}
                                                        group
                                                        type="text"
                                                        pattern="^[ 0-9]*$"
                                                        handleChange={handleChange}
                                                        required
                                                        input={[
                                                            {
                                                                name: 'pos_mobile_phone',
                                                                value: values.pos_mobile_phone,
                                                                maxLength: '25',
                                                                placeholder: '999 999 999',
                                                                isValid: touched.pos_mobile_phone && !errors.pos_mobile_phone,
                                                                isInvalid: !!errors.pos_mobile_phone,
                                                                error: (typeof errors.pos_mobile_phone === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_mobile_phone.type}).toString().replace('$param$', errors.pos_mobile_phone.param).replace('$param2$', errors.pos_mobile_phone.param2) : store.dispatch({type:'translate', 'text': errors.pos_mobile_phone}),
                                                                // className: 'field-number',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
                                                                }
                                                            },

                                                        ]}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'email_address_title'})}
                                                        name="pos_email_address"
                                                        type="text"
                                                        required
                                                        maxLength="255"
                                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                        value={values.pos_email_address}
                                                        handleChange={handleChange}
                                                        isValid={touched.pos_email_address && !errors.pos_email_address}
                                                        isInvalid={!!errors.pos_email_address}
                                                        error={(typeof errors.pos_email_address === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_email_address.type}).toString().replace('$param$', errors.pos_email_address.param).replace('$param2$', errors.pos_email_address.param) : store.dispatch({type:'translate', 'text': errors.pos_email_address})}
                                                    />                                                    
                                                </Form.Row>
                                                
                                                {/* Email Address */}
                                                <Form.Row>
                                                {/* <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'email_address_title'})}
                                                        name="pos_email_address"
                                                        type="text"
                                                        required
                                                        maxLength="255"
                                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                        value={values.pos_email_address}
                                                        handleChange={handleChange}
                                                        isValid={touched.pos_email_address && !errors.pos_email_address}
                                                        isInvalid={!!errors.pos_email_address}
                                                        error={(typeof errors.pos_email_address === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_email_address.type}).toString().replace('$param$', errors.pos_email_address.param).replace('$param2$', errors.pos_email_address.param) : store.dispatch({type:'translate', 'text': errors.pos_email_address})}
                                                    /> */}
                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput 
                                                        col="12"
                                                        labelName={store.dispatch({type:'translate', 'text': 'address'})}
                                                        name="pos_address"
                                                        type="textarea"
                                                        required
                                                        maxLength="150"
                                                        value={values.pos_address}
                                                        handleChange={
                                                            // value => setFieldValue('pos_address', value)
                                                            (val) => {
                                                                let curVal = val ? val.toUpperCase() : "";
                                                                setFieldValue("pos_address", curVal);
                                                            }
                                                        }
                                                        isValid={touched.pos_address && !errors.pos_address}
                                                        isInvalid={!!errors.pos_address}
                                                        error={(typeof errors.pos_address === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_address.type}).toString().replace('$param$', errors.pos_address.param).replace('$param2$', errors.pos_address.param2) : store.dispatch({type:'translate', 'text': errors.pos_address})}
                                                    />
                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'province'})}
                                                        name="pos_province"
                                                        type="select"
                                                        required
                                                        value={values.pos_province}
                                                        // onChange={setFieldValue}
                                                        onChange={(name,value) => {
                                                            setFieldValue('pos_city', '');
                                                            setFieldValue('pos_district_master', '');
                                                            setFieldValue('pos_subdistrict_master', '');

                                                            setFieldValue('pos_district', '');
                                                            setFieldValue('pos_subdistrict', '');

                                                            this.onChangeProvincePos(value);
                                                            setFieldValue(name, value);
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_province === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_province.type}).toString().replace('$param$', errors.pos_province.param).replace('$param2$', errors.pos_province.param2) : store.dispatch({type:'translate', 'text': errors.pos_province})}
                                                        touched={touched.pos_province}
                                                        option={this.props.master_province}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'city'})}
                                                        name="pos_city"
                                                        type="select"
                                                        required
                                                        value={values.pos_city}
                                                        onChange={(name, value) => {
                                                            setFieldValue('pos_district_master', '');
                                                            setFieldValue('pos_subdistrict_master', '');

                                                            setFieldValue('pos_district', '');
                                                            setFieldValue('pos_subdistrict', '');

                                                            this.onChangeBoCity(value);
                                                            setFieldValue(name, value);
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_city === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_city.type}).toString().replace('$param$', errors.pos_city.param).replace('$param2$', errors.pos_city.param2) : store.dispatch({type:'translate', 'text': errors.pos_city})}
                                                        touched={touched.pos_city}
                                                        option={this.state.masterCityPos}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'postal_code'})}
                                                        name="pos_postalcode"
                                                        type="text"
                                                        maxLength="10"
                                                        pattern="^[0-9]*$"
                                                        required
                                                        value={values.pos_postalcode}
                                                        handleChange={handleChange}
                                                        isValid={touched.pos_postalcode && !errors.pos_postalcode}
                                                        isInvalid={!!errors.pos_postalcode}
                                                        error={(typeof errors.pos_postalcode === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_postalcode.type}).toString().replace('$param$', errors.pos_postalcode.param).replace('$param2$', errors.pos_postalcode.param2) : store.dispatch({type:'translate', 'text': errors.pos_postalcode})}
                                                    />
                                                </Form.Row>

                                                {/* BO Kecamatan, BO Kelurahan dari master data */}
                                                <Form.Row>
                                                <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'district'})}
                                                        name="pos_district_master"
                                                        type="select"
                                                        required
                                                        value={values.pos_district_master}
                                                        onChange={(name,value) => {
                                                            setFieldValue('pos_subdistrict_master', '');
                                                            setFieldValue('pos_subdistrict', '');
                                                            setFieldValue('pos_district', value.label);

                                                            this.onChangeBoDistrict(value);
                                                            setFieldValue(name, value);
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_district_master === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_district_master.type}).toString().replace('$param$', errors.pos_district_master.param).replace('$param2$', errors.pos_district_master.param2) : store.dispatch({type:'translate', 'text': errors.pos_district_master})}
                                                        touched={touched.pos_district_master}
                                                        option={this.state.masterBoDistrict}
                                                    />
                                                <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'subdistrict'})}
                                                        name="pos_subdistrict_master"
                                                        type="select"
                                                        required
                                                        value={values.pos_subdistrict_master}
                                                        onChange={(name,value) => {
                                                            setFieldValue('pos_subdistrict', value.label);
                                                            setFieldValue(name, value);
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_subdistrict_master === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_subdistrict_master.type}).toString().replace('$param$', errors.pos_subdistrict_master.param).replace('$param2$', errors.pos_subdistrict_master.param2) : store.dispatch({type:'translate', 'text': errors.pos_subdistrict_master})}
                                                        touched={touched.pos_subdistrict_master}
                                                        option={this.state.masterBoSubDistrict}
                                                    />                                                           
                                                </Form.Row>
                                                {/* <Form.Row>
                                                <CustomInput 
                                                    col="3"
                                                    labelName={store.dispatch({type:'translate', 'text': 'subdistrict'})}
                                                    name="pos_subdistrict"
                                                    type="text"
                                                    minLength="1"
                                                    // pattern="^[0-9]*$"
                                                    required
                                                    value={values.pos_subdistrict}
                                                    handleChange={handleChange}
                                                    isValid={touched.pos_subdistrict && !errors.pos_subdistrict}
                                                    isInvalid={!!errors.pos_subdistrict}
                                                    error={(typeof errors.pos_subdistrict === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_subdistrict.type}).toString().replace('$param$', errors.pos_subdistrict.param).replace('$param2$', errors.pos_subdistrict.param2) : store.dispatch({type:'translate', 'text': errors.pos_subdistrict})}
                                                />                                                    
                                                <CustomInput 
                                                    col="3"
                                                    labelName={store.dispatch({type:'translate', 'text': 'district'})}
                                                    name="pos_district"
                                                    type="text"
                                                    minLength="1"
                                                    // pattern="^[0-9]*$"
                                                    required
                                                    value={values.pos_district}
                                                    handleChange={handleChange}
                                                    isValid={touched.pos_district && !errors.pos_district}
                                                    isInvalid={!!errors.pos_district}
                                                    error={(typeof errors.pos_district === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_district.type}).toString().replace('$param$', errors.pos_district.param).replace('$param2$', errors.pos_district.param2) : store.dispatch({type:'translate', 'text': errors.pos_district})}
                                                />
                                                </Form.Row> */}

                                                {/* RT, RW*/}
                                                <Form.Row>
                                                <CustomInput 
                                                    col="3"
                                                    labelName="RT"
                                                    info={store.dispatch({type:'translate', 'text': 'info_rt_rw'})}
                                                    name="pos_rt"
                                                    type="text"
                                                    minLength="3"
                                                    maxLength="3"
                                                    pattern="^[0-9]*$"
                                                    required
                                                    value={values.pos_rt}
                                                    handleChange={handleChange}
                                                    isValid={touched.pos_rt && !errors.pos_rt}
                                                    isInvalid={!!errors.pos_rt}
                                                    error={(typeof errors.pos_rt === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_rt.type}).toString().replace('$param$', errors.pos_rt.param).replace('$param2$', errors.pos_rt.param2) : store.dispatch({type:'translate', 'text': errors.pos_rt})}
                                                />
                                                <CustomInput 
                                                    col="3"
                                                    labelName="RW"
                                                    info={store.dispatch({type:'translate', 'text': 'info_rt_rw'})}
                                                    name="pos_rw"
                                                    type="text"
                                                    minLength="3"
                                                    maxLength="3"
                                                    pattern="^[0-9]*$"
                                                    required
                                                    value={values.pos_rw}
                                                    handleChange={handleChange}
                                                    isValid={touched.pos_rw && !errors.pos_rw}
                                                    isInvalid={!!errors.pos_rw}
                                                    error={(typeof errors.pos_rw === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_rw.type}).toString().replace('$param$', errors.pos_rw.param).replace('$param2$', errors.pos_rw.param2) : store.dispatch({type:'translate', 'text': errors.pos_rw})}
                                                />                                                      
                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'identity_type'})}
                                                        name="pos_id_type"
                                                        type="select"
                                                        required
                                                        value={values.pos_id_type}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_id_type === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_id_type.type}).toString().replace('$param$', errors.pos_id_type.param).replace('$param2$', errors.pos_id_type.param2) : store.dispatch({type:'translate', 'text': errors.pos_id_type})}
                                                        touched={touched.pos_id_type}
                                                        option={store.state.master.master_id_type}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'identity_number'})}
                                                        name="pos_idcard_no"
                                                        type="text"
                                                        maxLength="16"
                                                        pattern="^[0-9]*$"
                                                        required
                                                        value={values.pos_idcard_no}
                                                        handleChange={handleChange}
                                                        isValid={touched.pos_idcard_no && !errors.pos_idcard_no}
                                                        isInvalid={!!errors.pos_idcard_no}
                                                        error={(typeof errors.pos_idcard_no === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_idcard_no.type}).toString().replace('$param$', errors.pos_idcard_no.param).replace('$param2$', errors.pos_idcard_no.param2) : store.dispatch({type:'translate', 'text': errors.pos_idcard_no})}
                                                    />
                                                    {
                                                        (typeof values.pos_id_type === 'object' && values.pos_id_type.value === "1") || values.pos_id_type === "1" ? 
                                                        <CustomInput 
                                                            col="4"
                                                            labelName={store.dispatch({type:'translate', 'text': 'identity_expired'})}
                                                            required
                                                            readOnly
                                                            name="pos_idcard_expdate"
                                                            type="text"
                                                            value={store.dispatch({type:'translate', 'text': 'lifetime'})}
                                                            handleChange={handleChange}
                                                            isValid={touched.pos_idcard_expdate && !errors.pos_idcard_expdate}
                                                            isInvalid={!!errors.pos_idcard_expdate}
                                                            error={ (typeof errors.pos_idcard_expdate === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_idcard_expdate.type}).toString().replace('$param$',errors.pos_idcard_expdate.param).replace('$param2$',errors.pos_idcard_expdate.param2) : store.dispatch({type:'translate', 'text': errors.pos_idcard_expdate})}
                                                        />
                                                        :
                                                        <CustomInput 
                                                            col="4"
                                                            labelName={store.dispatch({type:'translate', 'text': 'identity_expired'})}
                                                            name="pos_idcard_expdate"
                                                            type="date"
                                                            required
                                                            dateType="expireddate"
                                                            value={values.pos_idcard_expdate}
                                                            handleChange={date => setFieldValue('pos_idcard_expdate', date)}
                                                            isValid={touched.pos_idcard_expdate && !errors.pos_idcard_expdate}
                                                            isInvalid={!!errors.pos_idcard_expdate}
                                                            error={ (typeof errors.pos_idcard_expdate === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_idcard_expdate.type}).toString().replace('$param$',errors.pos_idcard_expdate.param).replace('$param2$',errors.pos_idcard_expdate.param2) : store.dispatch({type:'translate', 'text': errors.pos_idcard_expdate})}
                                                            placeholder="DD/MM/YYYY"
                                                        />
                                                    }
                                                </Form.Row>
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'occupation_code'})}
                                                        name="pos_occupation_code"
                                                        type="select"
                                                        required
                                                        value={values.pos_occupation_code}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_occupation_code === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_occupation_code.type}).toString().replace('$param$', errors.pos_occupation_code.param).replace('$param2$', errors.pos_occupation_code.param2) : store.dispatch({type:'translate', 'text': errors.pos_occupation_code})}
                                                        touched={touched.pos_occupation_code}
                                                        option={store.state.master.master_occupation_code}
                                                    />
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'occupation_name'})}
                                                        info={store.dispatch({type:'translate', 'text': 'occupation_info'})}
                                                        name="pos_occupation_name"
                                                        type="text"
                                                        maxLength="60"
                                                        // pattern="^[0-9]*$"
                                                        required
                                                        value={values.pos_occupation_name}
                                                        handleChange={
                                                            (el) => {
                                                                let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                                setFieldValue("pos_occupation_name", elVal);
                                                            }
                                                        }
                                                        isValid={touched.pos_occupation_name && !errors.pos_occupation_name}
                                                        isInvalid={!!errors.pos_occupation_name}
                                                        error={(typeof errors.pos_occupation_name === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_occupation_name.type}).toString().replace('$param$', errors.pos_occupation_name.param).replace('$param2$', errors.pos_occupation_name.param2) : store.dispatch({type:'translate', 'text': errors.pos_occupation_name})}
                                                    />
                                                </Form.Row>

                                                {/* NPWP BO */}
                                                <Form.Row>
                                                <CustomInput 
                                                        labelName={store.dispatch({type:'translate', 'text': 'npwp_number'})}
                                                        col="6"
                                                        name="pos_npwp_number"
                                                        type="text"
                                                        maxLength="15"
                                                        minLength="15"
                                                        pattern="^[0-9]*$"
                                                        value={values.pos_npwp_number}
                                                        handleChange={(event) => {

                                                            setFieldValue('pos_npwp_number', event.target.value);

                                                            let npwpValue = event.target.value;
                                                            if(npwpValue !== "") {
                                                                setFieldValue('pos_no_npwp_reason', "");
                                                                this.setState({disableNpwpReason: true});
                                                            } else {
                                                                this.setState({disableNpwpReason: false});
                                                            }
                                                        }}
                                                        isValid={touched.pos_npwp_number && !errors.pos_npwp_number}
                                                        isInvalid={!!errors.pos_npwp_number}
                                                        error={ (typeof errors.pos_npwp_number === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_npwp_number.type}).toString().replace('$param$',errors.pos_npwp_number.param).replace('$param2$',errors.pos_npwp_number.param2) : store.dispatch({type:'translate', 'text': errors.pos_npwp_number})}
                                                    />

                                                <CustomInput
                                                    labelName="Alasan tidak mengisi data NPWP"
                                                    col="6"
                                                    name="pos_no_npwp_reason"
                                                    type="select"
                                                    required
                                                    text_helper="*Harus dipilih salah satu apabila tidak mengisi NPWP. Apabila mengisi data NPWP, abaikan opsi ini."
                                                    hideElement={false}
                                                    isDisabled={this.state.disableNpwpReason}
                                                    option={[
                                                        {value: '1', label: "Belum memiliki/mengajukan NPWP"},
                                                        {value: '2', label: "Tidak diwajibkan memiliki NPWP sesuai dengan ketentuan undang-undang dan juga tidak memiliki BO (Beneficial Owner)"},
                                                        {value: '3', label: "Telah memiliki NPWP namun tidak dibawa / hilang / alasan lain dan nomor tidak ingat"}
                                                    ]}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    placeholder="Pilih salah satu"
                                                    value={values.pos_no_npwp_reason}
                                                    touched={touched.pos_no_npwp_reason}
                                                    error={(typeof errors.pos_no_npwp_reason === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_no_npwp_reason.type}).toString().replace('$param$', errors.pos_no_npwp_reason.param).replace('$param2$', errors.pos_no_npwp_reason.param2) : store.dispatch({type:'translate', 'text': errors.pos_no_npwp_reason})}
                                                    />                                                    
                                                </Form.Row>

                                                <Form.Row>
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={`${store.dispatch({type:'translate', 'text': 'company_name'})}`}
                                                        name="pos_company_name"
                                                        type="text"
                                                        maxLength="100"
                                                        // pattern="^[A-Z a-z]+"
                                                        required
                                                        value={values.pos_company_name}
                                                        handleChange={handleChange}
                                                        isValid={touched.pos_company_name && !errors.pos_company_name}
                                                        isInvalid={!!errors.pos_company_name}
                                                        error={(typeof errors.pos_company_name === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_company_name.type}).toString().replace('$param$', errors.pos_company_name.param).replace('$param2$', errors.pos_company_name.param2) : store.dispatch({type:'translate', 'text': errors.pos_company_name})}
                                                    />
                                                </Form.Row>
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="12"
                                                        labelName={store.dispatch({type:'translate', 'text': 'address'})}
                                                        name="pos_company_address"
                                                        type="textarea"
                                                        maxLength="150"
                                                        required
                                                        value={values.pos_company_address}
                                                        handleChange={value => setFieldValue('pos_company_address', value)}
                                                        isValid={touched.pos_company_address && !errors.pos_company_address}
                                                        isInvalid={!!errors.pos_company_address}
                                                        error={(typeof errors.pos_company_address === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_company_address.type}).toString().replace('$param$', errors.pos_company_address.param).replace('$param2$', errors.pos_company_address.param2) : store.dispatch({type:'translate', 'text': errors.pos_company_address})}
                                                    />
                                                </Form.Row>
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'province'})}
                                                        name="pos_company_province"
                                                        type="select"
                                                        value={values.pos_company_province}
                                                        // onChange={setFieldValue}
                                                        onChange={(name,value) => {
                                                            this.onChangeProvincePosCompany(value);
                                                            setFieldValue(name, value);
                                                            setFieldValue('pos_company_city', '');
                                                        }}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_company_province === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_company_province.type}).toString().replace('$param$', errors.pos_company_province.param).replace('$param2$', errors.pos_company_province.param2) : store.dispatch({type:'translate', 'text': errors.pos_company_province})}
                                                        touched={touched.pos_company_province}
                                                        option={this.props.master_province}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'city'})}
                                                        name="pos_company_city"
                                                        type="select"
                                                        value={values.pos_company_city}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_company_city === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_company_city.type}).toString().replace('$param$', errors.pos_company_city.param).replace('$param2$', errors.pos_company_city.param2) : store.dispatch({type:'translate', 'text': errors.pos_company_city})}
                                                        touched={touched.pos_company_city}
                                                        option={this.state.masterCityPosCompany}
                                                    />
                                                    <CustomInput 
                                                        col="4"
                                                        labelName={store.dispatch({type:'translate', 'text': 'postal_code'})}
                                                        name="pos_company_postalcode"
                                                        type="text"
                                                        maxLength="5"
                                                        pattern="^[0-9]*$"
                                                        required
                                                        value={values.pos_company_postalcode}
                                                        handleChange={handleChange}
                                                        isValid={touched.pos_company_postalcode && !errors.pos_company_postalcode}
                                                        isInvalid={!!errors.pos_company_postalcode}
                                                        error={(typeof errors.pos_company_postalcode === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_company_postalcode.type}).toString().replace('$param$', errors.pos_company_postalcode.param).replace('$param2$', errors.pos_company_postalcode.param2) : store.dispatch({type:'translate', 'text': errors.pos_company_postalcode})}
                                                    />
                                                </Form.Row>
                                                <Form.Row>
                                                    <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'home_phone'})}
                                                        // info={store.dispatch({type:'translate', 'text': 'home_phone_info'})}
                                                        group
                                                        type="text"
                                                        pattern="^[ 0-9]*$"
                                                        required
                                                        handleChange={handleChange}
                                                        input={[
                                                            {
                                                                name: 'pos_company_phone_number',
                                                                value: values.pos_company_phone_number,
                                                                maxLength: '25',
                                                                placeholder: '999 999 999',
                                                                isValid: touched.pos_company_phone_number && !errors.pos_company_phone_number,
                                                                isInvalid: !!errors.pos_company_phone_number,
                                                                error: (typeof errors.pos_company_phone_number === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_company_phone_number.type}).toString().replace('$param$', errors.pos_company_phone_number.param).replace('$param2$', errors.pos_company_phone_number.param2) : store.dispatch({type:'translate', 'text': errors.pos_company_phone_number}),
                                                                // className: 'field-number',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
                                                                }
                                                            },

                                                        ]}
                                                    />
                                                    <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'fax_number'})}
                                                        // info={store.dispatch({type:'translate', 'text': 'home_phone_info'})}
                                                        group
                                                        type="text"
                                                        pattern="^[ 0-9]*$"
                                                        handleChange={handleChange}
                                                        input={[
                                                            {
                                                                name: 'pos_company_fax',
                                                                value: values.pos_company_fax,
                                                                maxLength: '25',
                                                                placeholder: '999 999 999',
                                                                isValid: touched.pos_company_fax && !errors.pos_company_fax,
                                                                isInvalid: !!errors.pos_company_fax,
                                                                error: (typeof errors.pos_company_fax === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_company_fax.type}).toString().replace('$param$', errors.pos_company_fax.param).replace('$param2$', errors.pos_company_fax.param2) : store.dispatch({type:'translate', 'text': errors.pos_company_fax}),
                                                                // className: 'field-number',
                                                                handleKeyUp: (ev) => {
                                                                    ev.target.value = ev.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
                                                                }
                                                            },

                                                        ]}
                                                    />
                                                </Form.Row>
                                                <Form.Row>
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'income_per_annum'})}
                                                        name="pos_annual_income"
                                                        type="select"
                                                        required
                                                        value={values.pos_annual_income}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_annual_income === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_annual_income.type}).toString().replace('$param$', errors.pos_annual_income.param).replace('$param2$', errors.pos_annual_income.param2) : store.dispatch({type:'translate', 'text': errors.pos_annual_income})}
                                                        touched={touched.pos_annual_income}
                                                        option={store.state.master.master_annual_income}
                                                    />
                                                    <CustomInput 
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'source_of_fund'})}
                                                        name="pos_source_of_income"
                                                        type="select"
                                                        required
                                                        value={values.pos_source_of_income}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder={store.dispatch({type:'translate', 'text': 'placeholder_select'})}
                                                        error={(typeof errors.pos_source_of_income === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_source_of_income.type}).toString().replace('$param$', errors.pos_source_of_income.param).replace('$param2$', errors.pos_source_of_income.param2) : store.dispatch({type:'translate', 'text': errors.pos_source_of_income})}
                                                        touched={touched.pos_source_of_income}
                                                        option={store.state.master.master_source_income}
                                                    />
                                                </Form.Row>

                                                <Form.Row>
                                                <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'pos_ktp_file'})}
                                                        name="pos_ktp_file"
                                                        type="file"
                                                        accept="image/jpg,image/png,image/jpeg"
                                                        capture
                                                        required={!store.state.registration.pos_ktp_path && !values.pos_ktp_file}
                                                        exists={store.state.registration.pos_ktp_path}
                                                        handleChange={(file) => setFieldValue('pos_ktp_file', file)}
                                                        isValid={touched.pos_ktp_file && !errors.pos_ktp_file}
                                                        isInvalid={!!errors.pos_ktp_file}
                                                        error={ (typeof errors.pos_ktp_file === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_ktp_file.type}).toString().replace('$param$',errors.pos_ktp_file.param).replace('$param2$',errors.pos_ktp_file.param2) : store.dispatch({type:'translate', 'text': errors.pos_ktp_file})}
                                                    />

                                                <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({type:'translate', 'text': 'pos_npwp_file'})}
                                                        name="pos_npwp_file"
                                                        type="file"
                                                        accept="image/jpg,image/png,image/jpeg"
                                                        capture
                                                        required={!store.state.registration.pos_npwp_path && !values.pos_npwp_file}
                                                        exists={store.state.registration.pos_npwp_path}
                                                        handleChange={(file) => setFieldValue('pos_npwp_file', file)}
                                                        isValid={touched.pos_npwp_file && !errors.pos_npwp_file}
                                                        isInvalid={!!errors.pos_npwp_file}
                                                        error={ (typeof errors.pos_npwp_file === 'object') ? store.dispatch({type:'translate', 'text': errors.pos_npwp_file.type}).toString().replace('$param$',errors.pos_npwp_file.param).replace('$param2$',errors.pos_npwp_file.param2) : store.dispatch({type:'translate', 'text': errors.pos_npwp_file})}
                                                    />
                                                </Form.Row>
                                            </fieldset>
                                            </Fragment>
                                            : ''
                                        }
                                        <div className={cx('row', styleRegis['wrap-btn'])}>
                                            <div className="container-btn col-md-12 text-center">
                                                <div className='button-control'>
                                                    <ButtonCustom class="yellow-btn" value={store.dispatch({type:'translate', 'text': 'btn_prev'})} onButtonClick={(value) => store.dispatch({type:'clickButton',field:'prev'})} />
                                                    <ButtonCustom id="job-information-btn" class="blue-btn" value={store.dispatch({type:'translate', 'text': 'btn_next'})} type="submit" loading={isSubmitting} disabled={isSubmitting}/>
                                                </div>
                                            </div>
                                        </div>

                                        <input type="hidden" name="company_district"/>
                                        <input type="hidden" name="company_subdistrict"/>

                                        {
                                        (parseInt(this.state.valueOccupation) === 1 || parseInt(this.state.valueOccupation) === 2) ?
                                        <Fragment>
                                        <input type="hidden" name="pos_district"/>
                                        <input type="hidden" name="pos_subdistrict"/>
                                        </Fragment>
                                        : ''
                                        }
                                    </Form>
                                </Fragment>
                                )}
                            </Formik>
                		)
                    }
                }
            </RootContext.Consumer>
        )
	}
}
export default props => (
    <RootContext.Consumer>
    {
        store => {
            return (
                <JobInformationForm {...props} cur_occupation={store.state.registration.occupation} cur_job_position={store.state.registration.job_position} cur_field_of_business={store.state.registration.field_of_business} cur_country={store.state.registration.company_country} cur_province={store.state.registration.company_province} cur_province_pos={store.state.registration.pos_province} cur_province_pos_company={store.state.registration.pos_company_province} master_province={store.state.master.master_province_ksei} master_city={store.state.master.master_city_ksei} posNpwpNumber={store.state.registration.pos_npwp_number} cur_company_city={store.state.registration.company_city} cur_company_district_master={store.state.registration.company_district_master} cur_pos_city={store.state.registration.pos_city} cur_pos_district_master={store.state.registration.pos_district_master} />
            )
        }
    }
    </RootContext.Consumer>
)